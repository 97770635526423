import React, { useEffect } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
const layoutType = getPlatformType();
import { ImageBackground } from 'react-native';
const WIN_HEIGHT = Dimensions.get('window').height;
import IdRef from '../../../../assets/images/idRef.png';
import BackButton from '../../../../commonComponents/BackButton';
import getPlatformType, { getImage } from '../../../../helpers/getPlatform';
import { ListStatusCard } from '../../../openFlow/atoms/listStatusCard';
import ODSButton from '../../../../../openbrix-design-system/ui/atoms/ODSButton';
import { ChildrenView } from '../../../idVerification/styles';
import OpenflowCard from '../../../../../openbrix-design-system/ui/molecules/propertyBox/openflowCard';
import { replaceAction } from '../../../../helpers/navigationHelpers';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { getTokens } from '../../../../../openbrix-design-system/design-tokens/color-tokens';

const OpenFlowContainer = (props) => {
  const { children, openFlowData,navigation ,isOpenflowHome} = props;
  const { title, desc, message, buttonText, listIcon = null} = openFlowData;
  const globalState = useSelector((state) => state?.tenant);
  const themeObj=getTokens('light').colors


  useEffect(() => {
    window.scrollTo(11400, null);
  }, []);
  return (
    <View style={{ minHeight: WIN_HEIGHT - 300 }}>
      <View style={{ width: '100%' }}>
        <ImageBackground
          source={getImage('openflow.png')}
          style={{ width: '100%', height: 300 }}
          resizeMode={'cover'}
        >
          <View
            style={layoutType=="phone"?{
              flexDirection:'column',
              marginHorizontal:20,
              marginTop:20
            }: { 
              margin: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <ODSButton
              rIcon={'TB-Arrow-Left'}
              restStyle={{ width: 200 }}
              type="secondary"
              disabled={false}
              onPress={() =>isOpenflowHome?replaceAction(props?.navigation, isOpenflowHome):replaceAction(props?.navigation, isOpenflowHome?"Dashboard": 'My Tenancy',{id:globalState?.flowIdInfo?.openflow_id})}
            >
           {isOpenflowHome?isOpenflowHome=="My Tenancy Home"?"My Flos":isOpenflowHome: "Back"}
            </ODSButton>
          {isOpenflowHome?null:  <View style={layoutType=="phone"? {width:"100%",marginTop:20,marginBottom:20}: { width: 500 }}>
              <OpenflowCard
                id={'asdsdsd'}
                key={'lti'}
                onPress={() => {}}
                type={buttonText}
                title={title}
                message={message}
                description={desc}
                listIcon={listIcon}
                IocnColor={
                  globalState?.flowIdInfo?.is_renewed? themeObj.main.pink[400] : themeObj.main.blue[400]
                }
              />
            </View>}
          </View>
        </ImageBackground>
        <ChildrenView>{children}</ChildrenView>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({});
export default OpenFlowContainer;
