import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';

import Gurantor from './gurantor';
import UploadRef from './uploadReference';
import { COLORS } from '../../../../constants/colorPallete';
import { TabContainer } from '../../../idVerification/styles';
import getPlatformType from '../../../../helpers/getPlatform';
import { tenantActions } from '../../openFlowRedux/tenant.actions';
import IdVerification from '../../../idVerification/idverification';
import BackGroundGenerator from '../../../LandingPages/common/BackGroundGenerator';
import { MainSection } from '../../style';
import ODSTabs from '../../../../../openbrix-design-system/ui/atoms/ODSTabs';
import { getTokens } from '../../../../../openbrix-design-system/design-tokens/color-tokens';

const IdAndRef = (props) => {
  const dispatch = useDispatch();
  const layoutType = getPlatformType();
  const [teanants, setUser] = React.useState(null);
  const [flowId, setFlowId] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openFlow, setOpenFlowData] = React.useState({});
  const [reference, setReference] = React.useState(null);
  const [requestedDocs, setRequestDoc] = React.useState([]);
  const [latestUpdate, setLatestUpdate] = React.useState(null);
  const [openFlowGuarantor, setOpenFlowGuarantor] = React.useState({});
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);

  useEffect((_) => {
    window.scrollTo(11400, null);
  }, []);

  useEffect(() => {
    getOpenflowData();
    if (props?.route?.params?.id) {
      setFlowId(props?.route?.params?.id);
    }
  }, [props?.route?.params?.id, userId]);

  const getOpenflowData = () => {
    if (props?.route?.params?.id && userId) {
      dispatch(
        tenantActions.getOpenflowData(props?.route?.params?.id, (openflowData) => {
          const currentTenant = openflowData?.tenants?.find(
            (tenant) => tenant?.app_user_id == userId
          );
          setUser(currentTenant);
          setOpenFlowGuarantor(currentTenant?.open_flow_guarantor);
          setOpenFlowData(openflowData);
          if (currentTenant?.fccReference) {
            setReference(currentTenant.fccReference);
            if (
              currentTenant.fccReference.fcc_references_updates &&
              currentTenant.fccReference.fcc_references_updates.length > 0
            ) {
              const referenceUpdates = currentTenant.fccReference.fcc_references_updates;
              if (referenceUpdates[0] && Object.keys(referenceUpdates[0]).length > 0) {
                referenceUpdates.sort(
                  (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
                );
                const latestUpdate1 = {
                  ...referenceUpdates[0],
                  handler: JSON.parse(referenceUpdates[0].handler),
                  progress: JSON.parse(referenceUpdates[0].progress),
                  documents: JSON.parse(referenceUpdates[0].documents),
                };
                if (latestUpdate1.handler && latestUpdate1.handler.length > 0) {
                  const handler = latestUpdate1.handler[0];
                  latestUpdate1.handlerName = `${handler.title[0]} ${handler.first_name[0]} ${handler.last_name[0]}`;
                  latestUpdate1.handlerContactNumber = `${handler.phone_number[0]}`;
                }
                if (latestUpdate1.progress && latestUpdate1.progress.length > 0) {
                  latestUpdate1.currentEmployment = {
                    comments: latestUpdate1.progress[0].current_employment[0],
                    status: latestUpdate1.progress[0].current_employment_status[0],
                    last_updated: latestUpdate1.progress[0].current_employment_date_last_updated[0],
                  };
                  latestUpdate1.creditSearch = {
                    comments: latestUpdate1.progress[0].search_details[0],
                    status: latestUpdate1.progress[0].search_details_status[0],
                    last_updated: latestUpdate1.progress[0].search_details_date_last_updated[0],
                  };
                  latestUpdate1.currentLiving = {
                    comments: latestUpdate1.progress[0].current_living[0],
                    status: latestUpdate1.progress[0].current_living_status[0],
                    last_updated: latestUpdate1.progress[0].current_living_date_last_updated[0],
                  };
                  latestUpdate1.tenantSearch = {
                    comments: latestUpdate1.progress[0].tenant_database[0],
                    status: latestUpdate1.progress[0].tenant_database_status[0],
                    last_updated: latestUpdate1.progress[0].tenant_database_date_last_updated[0],
                  };
                }
                setLatestUpdate(latestUpdate1);
              } else {
                setLatestUpdate(null);
              }
            }
          } else {
            setReference(null);
            setLatestUpdate(null);
          }
        })
      );
      dispatch(
        tenantActions.getFccRequestedReferenceDocs(props?.route?.params?.id, (documents) => {
          setRequestDoc(documents.data);
        })
      );
    }
  };
  const themeObj = getTokens('light').colors;
  const TabView = () => {
    return (
      <ODSTabs
        items={[' ID Verification', 'Full Referencing', 'Guarantor']}
        selectedColor={themeObj.main.blue[700]}
        color={themeObj.main.blue[500]}
        activeTab={selectedIndex}
        onTabChange={(val) => setSelectedIndex(val)}
        restStyle={{
          borderRadius: 0,
          borderTopEndRadius: 8,
          borderTopStartRadius: 8,
          minHeight: 68,
          marginBottom: 20,
        }}
      />
     
    );
  };

  return (
    <BackGroundGenerator
      props={props}
      isTLYF
      isOpenflowContainer
      openFlowData={{
        title: 'ID & Referencing',
        desc: 'Please ensure you have verified your ID.Should your agent require you to be referenced, you can complete the reference process below.',
        buttonText: openFlow?.verification_status,
        message: openFlow?.verification_message,
        listIcon: "idref"
      }}
    >
      <MainSection>
        <View
          style={
            layoutType == 'web'
              ? { alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: 1050 }
              : { alignSelf: 'center', flexDirection: 'row', flexWrap: 'wrap', width: '100%' }
          }
        >

          <TabView/>
        </View>

        <View
          style={
            layoutType == 'web'
              ? { width: "90%", alignSelf: 'center'}
              : { width: '100%',}
          }
        >
          {selectedIndex == 0 && <IdVerification showDocsOnSingleSection={true} />}
          {selectedIndex == 1 && (
            <UploadRef
              openFlow={openFlow}
              reference={reference}
              requestedDocs={requestedDocs}
              latestUpdate={latestUpdate}
              currentTenant={teanants}
              navigation={props.navigation}
              onRefresh={() => getOpenflowData()}
              teanant={teanants}
              flowId={flowId}
            />
          )}
          {selectedIndex == 2 && <Gurantor teanant={teanants} flowId={flowId} />}
        </View>
      </MainSection>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({});
export default IdAndRef;
