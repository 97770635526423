

const generatePartnerUrl = (homeBaseUrl: string, type = "TILE" || "SERVICES", iFrameParams: any) => {

  const baseUrls = {
    tile: `${homeBaseUrl}tlyfe/tile`,
    services: `${homeBaseUrl}tlyfe/services`,
  };

  const params = {
    attribution_code: iFrameParams?.attribution_code,
    lead_reference: iFrameParams?.lead_reference, // Required
    first_name: iFrameParams?.first_name, // Will be URL encoded,
    postcode: iFrameParams?.postcode, // Will be URL encoded
    tenancy_start: iFrameParams?.tenancy_start,
    tenancy_status: iFrameParams?.tenancy_status, // Optional
  };

  const url = `${type == "TILE" ? baseUrls.tile : baseUrls.services}?${Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== "")
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join("&")}`;

  return url;
};
export default generatePartnerUrl;