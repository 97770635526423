import React, { useState } from 'react';
import { Dimensions, StyleSheet, View, Text, TouchableOpacity, Image } from 'react-native';
import getPlatformType, { getImage } from '../../helpers/getPlatform';
const layoutType = getPlatformType();
import { ImageBackground } from 'react-native';
const WIN_HEIGHT = Dimensions.get('window').height;
const WIN_WIDTH = Dimensions.get('window').width;

import BackGroundGenerator from '../LandingPages/common/BackGroundGenerator';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { replaceAction } from '../../helpers/navigationHelpers';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import { ChildrenView } from '../idVerification/styles';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import './ext.css';
import { useSelector } from 'react-redux';
import generatePartnerUrl from '../../../openbrix-design-system/utils/functions';
import { HOME_BASE_URL } from '../../helpers/connectionHelpers';
const Market = (props) => {
  const themeObj = getTokens('light').colors;
  const iFrameParams = useSelector((state) => state.authReducer?.iFrameParams);

  return (
    <BackGroundGenerator props={props} isTLYF>
      <View style={{ minHeight: WIN_HEIGHT - 300 }}>
        <View style={{ width: '100%' }}>
          <ImageBackground
            source={getImage('market.png')}
            style={{ width: '100%', height: 300 }}
            resizeMode={'cover'}
          >
            <ODSButton
              rIcon={'TB-Arrow-Left'}
              restStyle={{ marginTop: 20, marginLeft: 20, width: 200 }}
              type="secondary"
              disabled={false}
              onPress={() => replaceAction(props?.navigation, 'Dashboard')}
            >
              My Dashboard
            </ODSButton>
          </ImageBackground>

          <View
            style={{
              alignSelf: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 20,
              paddingHorizontal: 20,
              maxWidth: layoutType == 'web' ? 843 : '100%',
            }}
          >
            {/* <ImageBackground
              style={{ width: 300, height: 60 }}
              resizeMode="contain"
              source={getImage('homemarket.png', true)}
            />
            <ImageBackground
              style={{ width: 160, height: 36, marginTop: 10 }}
              resizeMode="contain"
              source={getImage('MarketplaceLogo.png', true)}
            /> */}
              <ODSText type="h3" textAlign="center">
               Marketplace
            </ODSText>
          
          </View>
          <div className="rounded">
              <iframe
                width={WIN_WIDTH+"px"}
                frameBorder="0"
                hspace="0"
                vspace="0"
                height="3000px"
                src= {generatePartnerUrl(HOME_BASE_URL,'SERVICES',iFrameParams)}
                title="Home box"
              ></iframe>
            </div>
        </View>
      </View>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({});
export default Market;
