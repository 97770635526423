import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getPlatformType from '../../../../helpers/getPlatform';
import { MainSection, Typography } from '../../style';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions } from './../../openFlowRedux/tenant.actions';
import BackGroundGenerator from '../../../LandingPages/common/BackGroundGenerator';
import { BoxShadow } from '../../../tds/style';
import { COLORS } from '../../../../constants/colorPallete';
import { TabContainer } from '../../../idVerification/styles';
import { formatDate } from '../../../../helpers/functionHelpers';
import { ODSText } from '../../../../../openbrix-design-system/ui/atoms/ODSText';
import { getTokens } from '../../../../../openbrix-design-system/design-tokens/color-tokens';
import ODSdocumentCard from '../../../../../openbrix-design-system/ui/molecules/propertyBox/ODSdocumentCard';
import ODSTabs from '../../../../../openbrix-design-system/ui/atoms/ODSTabs';
import ODSHomeBoxTile from '../../../../../openbrix-design-system/ui/atoms/ODSTiles/HomeboxTile';
import { HOME_BASE_URL } from '../../../../helpers/connectionHelpers';
import { useNavigation } from '@react-navigation/native';

const layoutType = getPlatformType();
const citizenAdviseLink = 'https://www.citizensadvice.org.uk/';
const governmentDepositLink = 'https://www.gov.uk/tenancy-deposit-protection';

const DepositProtection = (props) => {
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const dispatch = useDispatch();
  const [openFlow, setOpenFlow] = React.useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [paymentSchedule, setPaymentSchedule] = React.useState([]);
  const [depositCertificates, setDepositCertificates] = React.useState([]);
  const [openFlowRentCollection, setOpenFlowRentCollection] = React.useState(null);
  const iFrameParams = useSelector((state) => state.authReducer?.iFrameParams);


  const getCurrentTenant = () => {
    return openFlow?.tenants?.find((tenant) => tenant.app_user_id == userId);
  };

  useEffect(() => {
    getOpenflowData();
  }, [props?.route?.params?.id]);
  const getOpenflowData = () => {
    if (props?.route?.params?.id) {
      dispatch(
        tenantActions.getOpenflowData(props?.route?.params?.id, (openflow) => {
          if (openflow) {
            setOpenFlow(openflow);
            setOpenFlowRentCollection(openflow?.open_flow_rent_collection);
            const rawDepositCertificates = openflow?.open_flow_holding_deposit?.deposit_certificate;
            if (rawDepositCertificates) {
              const parsedDepositCertificates = JSON.parse(rawDepositCertificates);
              setDepositCertificates(parsedDepositCertificates);
            }
            if (openflow?.open_flow_rent_collection) {
              setPaymentSchedule(JSON.parse(openflow?.open_flow_rent_collection?.payment_plan));
            }
          }
        })
      );
    }
  };
  const openCertificate = (certificate) => {
    if (!certificate?.is_seen) {
      dispatch(
        tenantActions.viewOpenFlowDepositCertificate(
          {
            certificate_url: certificate.document_url,
            is_seen: true,
            deposit: true,
            flow_id: openFlow?.openflow_id,
          },
          () => {
            getOpenflowData();
          }
        )
      );
    }
    window.open(certificate?.document_url, '_blank', 'noreferrer');
  };
  const renderCertificate = (item, index) => {
    return (
      <ODSdocumentCard
        title={'Deposit Certificate'}
        description={formatDate(item.deposit_certificate_date)}
        is_seen={item.is_seen}
        onPress={() => {
          openCertificate(item);
        }}
        style={{ marginTop: 30 }}
      />
    );
  };
  const themeObj = getTokens('light').colors;
  const renderDetailsCard = () => {
    const currentTenant = getCurrentTenant();
    return (
      <View style={styles.paddingStyle}>
        <ODSText color="black" type={'h5'}>
          Congratulations!
        </ODSText>
        <ODSText marginTop={10} color="black" type={'sm'}>
          Your deposit has been protected!
        </ODSText>
        <ODSText marginTop={10} color="black" type={'sm'}>
          Further information on deposits is available on this{' '}
          <Typography
            onPress={() => window.open(governmentDepositLink, '_blank', 'noreferrer')}
            align={'flex-start'}
            size={16}
            weight={'400'}
            color={COLORS.LGREEN}
            underline
          >
            government website.
          </Typography>
        </ODSText>
        <ODSText marginTop={10} color="black" type={'sm'}>
          If you require any legal assistance, please contact your legal advisor or your local{' '}
          <Typography
            onPress={() => window.open(citizenAdviseLink, '_blank', 'noreferrer')}
            align={'flex-start'}
            size={16}
            weight={'400'}
            color={COLORS.LGREEN}
            underline
          >
            citizens advice.
          </Typography>
        </ODSText>
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: themeObj.neutral[300],
            marginVertical: 24,
          }}
        />

        <ODSText color="black" type={'h5'}>
          Deposit Protection Docs
        </ODSText>

        {depositCertificates && depositCertificates?.length
          ? depositCertificates.map((item, index) => renderCertificate(item, index))
          : null}

        <ODSText marginTop={20} color="black" type={'button'}>
          TDS Provider
        </ODSText>
        <ODSText marginTop={6} color="black" type={'sm'}>
          {openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.name}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          TDS Website
        </ODSText>
        <TouchableOpacity
              onPress={() => {
                window.open(openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.link, '_blank', 'noreferrer');
              }}>     
        <ODSText  marginTop={6} color={themeObj.main.blue['700']}  type={'sm'}>
          {openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.link}
        </ODSText>
        </TouchableOpacity>
      </View>
    );
  };

  const TabView = () => {
    return (
      <ODSTabs
        items={['Rent Collection', 'Home Setup']}
        selectedColor={themeObj.main.blue[700]}
        color={themeObj.main.blue[500]}
        activeTab={selectedIndex}
        onTabChange={(val) => setSelectedIndex(val)}
        restStyle={{
          borderRadius: 0,
          borderTopEndRadius: 8,
          borderTopStartRadius: 8,
          minHeight: 68,
          marginBottom: 20,
        }}
      />
    );
  };
  const navigation=useNavigation();
  const renderHomeSetup = () => {
    return (
      <View style={styles.paddingStyle}>
         <ODSHomeBoxTile homeBaseUrl={HOME_BASE_URL} iFrameParams={iFrameParams} navigation={navigation}/>
      </View>
    );
  };

  const renderRentCollection = () => {
    const currentTenant = getCurrentTenant();
    return (
      <View style={styles.paddingStyle}>
        <ODSText color="black" type={'h5'}>
          Rent Collection
        </ODSText>
        <ODSText marginTop={10} color="black" type={'sm'}>
          Your rent will Be collected from the account below. If your Agent has not completed the
          details then please check and update the details directly below.
        </ODSText>
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: themeObj.neutral[300],
            marginVertical: 24,
          }}
        />
        <ODSText color="black" type={'h5'}>
          Tenant Account Details
        </ODSText>
        
        <ODSText marginTop={20} color="black" type={'button'}>
        Account Name:
        </ODSText>
        <ODSText marginTop={6} color="black" type={'sm'}>
        {openFlowRentCollection ? '' : 'N/A'}
        </ODSText>
        <ODSText marginTop={20} color="black" type={'button'}>
        Account No:
        </ODSText>
        <ODSText marginTop={6} color="black" type={'sm'}>
        {openFlowRentCollection ? '' : 'N/A'}
        </ODSText>
        <ODSText marginTop={20} color="black" type={'button'}>
        Sort code:
        </ODSText>
        <ODSText marginTop={6} color="black" type={'sm'}>
        {openFlowRentCollection ? '' : 'N/A'}
        </ODSText>
        <ODSText marginTop={20} color="black" type={'button'}>
        Payee Referance:
        </ODSText>
        <ODSText marginTop={6} color="black" type={'sm'}>
        {openFlowRentCollection ? '' : 'N/A'}
        </ODSText>
      
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: themeObj.neutral[300],
            marginVertical: 24,
          }}
        />
        <ODSText color="black" type={'h5'}>
        Rent Collection Schedule
        </ODSText>
        
        {openFlowRentCollection ? ( 
        <ODSText marginTop={10} color="black" type={'sm'}>
        Your Agent has selected FCC paragon to collect your rent.Below is your rent collection
              Schedule{' '}
        </ODSText>
        ) : null}
          <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
            <Typography align={'flex-start'} size={20} weight={'400'} color={COLORS.LABELGREY}>
            
            </Typography>
          </View>
      
        <View style={[styles.rowFlexView, {}]}>
          <View style={{ flexDirection: 'column', flex: 0.5 }}>
          <ODSText marginTop={20} color="black" type={'button'}>
              Amount
            </ODSText>
          </View>
          <View style={styles.rowItem}>
          <ODSText marginTop={20} color="black" type={'button'}>
              Collection Date
            </ODSText>
          </View>
        </View>
        {openFlowRentCollection ? (
          paymentSchedule.map((row) => (
            <View style={[styles.rowFlexView]}>
              <View style={{ flexDirection: 'column', flex: 0.5 }}>
              <ODSText marginTop={20} color="black" type={'sm'}>
              {row.amount}
            </ODSText>
               
              </View>
              <View style={styles.rowItem}>
              <ODSText marginTop={20} color="black" type={'sm'}>
              {row.collectDate}
            </ODSText>
               
              </View>
            </View>
          ))
        ) : (
          <View style={[styles.rowFlexView]}>
            
            <View style={{ flexDirection: 'column', flex: 0.5 }}>
            <ODSText marginTop={20} color="black" type={'sm'}>
            N/A
            </ODSText>
             
            </View>
            <View style={styles.rowItem}>
            <ODSText marginTop={20} color="black" type={'sm'}>
              N/A
            </ODSText>
             
            </View>
          </View>
        )}
      </View>
    );
  };
  const renderTermsCard = () => {
    return (
      <>
        <TabView />
        {selectedIndex == 0 ? renderRentCollection() : null}
        {selectedIndex == 1 ? renderHomeSetup() : null}
      </>
    );
  };

  return (
    <BackGroundGenerator
      props={props}
      isTLYF
      isOpenflowContainer
      openFlowData={{
        title: 'Deposit Protect & Home Setup',
        desc: `Check your deposit protection document. Use our specialised suppliers to set up your new home.`,
        buttonText: openFlow?.property_status,
        message: '',
        listIcon: "depositprotect",
      }}
    >
      <MainSection
        style={{
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
          <BoxShadow style={{ padding: 0 }}>{renderDetailsCard()}</BoxShadow>
        </View>
        <View
          style={layoutType == 'phone' ? { flex: 1, marginTop: 26, width: '100%' } : { flex: 0.48 }}
        >
          <BoxShadow style={{ padding: 0 }}>{renderTermsCard()}</BoxShadow>
        </View>
      </MainSection>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({
  paddingStyle: {
    padding: layoutType == 'phone' ? 12 : 20,
  },
  rowFlexView:
    layoutType === 'phone'
      ? {
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 5,
          borderBottomWidth: 1,
          borderColor: '#D9D9D9',
          paddingBottom: 10,
          marginBottom: 10,
        }
      : {
          flexDirection: 'row',
          alignItems: 'center',
          paddingVertical: 5,
          borderBottomWidth: 1,
          borderColor: '#D9D9D9',
          paddingHorizontal: 20,
          paddingBottom: 10,
          marginBottom: 10,
        },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
    backgroundColor: 'white',
    borderRadius: 20,
  },
  whiteColor12Regular: { color: '#FFFFFF', fontSize: 12 },
  rowItem: { flexDirection: 'column', flex: 0.4 },
});
export default DepositProtection;
