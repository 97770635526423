import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { CheckBox } from 'react-native-elements';
import { Elements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { Image, ImageBackground, Platform, TouchableOpacity, View } from 'react-native';
import {
  AntDesign,
  Entypo,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import Modal from "react-native-modal";

import { LineSaperator } from '../tds/style';
import { CheckoutForm } from './checkoutForm';
import { Typography } from '../openFlow/style';
import { types } from '../../redux/actionTypes';
import { COLORS } from '../../constants/colorPallete';
import { showToaster } from '../../redux/actions/toast';
import getPlatformType from '../../helpers/getPlatform';
import { BoxShadow, Wrapper } from './styles';
import WideButton from '../../commonComponents/wideButton';
import DropDownPicker from '../../commonComponents/DropDownPicker';

import { STRIPEKEY } from '../../helpers/connectionHelpers';
import { tenantActions } from '../openFlow/openFlowRedux/tenant.actions';
import CustomTextInput from '../../commonComponents/customTextInput/customTextInput';
import { openFlow } from '../../constants/openFlo';
import moment from 'moment';
import { navigate } from '../../navigation/RootNavigation';
import PayByVoucher from './paybyVoucher';
import ODSButton from '../../../openbrix-design-system/ui/atoms/ODSButton';
import { getTokens } from '../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../openbrix-design-system/ui/atoms/ODSText';
import ODSBadges from '../../../openbrix-design-system/ui/atoms/ODSBadges';
import { ODSModal } from '../../../openbrix-design-system/ui/atoms/ODSModal/ODSModal.web';
import { useTheme } from '@emotion/react';
import { AccordianCard } from '../openFlow/screens/IdAndRefNew/uploadReference';
import RequestDocs from '../openFlow/molecules/requestDocs';
import { ODSIcon } from '../../../openbrix-design-system/ui/atoms/ODSIcon';
import ODSCheckbox from '../../../openbrix-design-system/ui/atoms/ODSCheckbox';

const PreRefrenceNew = (props) => {
  const hasUserSavedCookies = useSelector(
    (state) => state?.cookiesReducer?.userHasSavedCookiesPreferences
  );
  const authDataFromMobile = useSelector((state) => state?.appReducer?.mobileData?.data?.isWebView);

  let stripePromise = null;
  if (hasUserSavedCookies) {
    stripePromise = loadStripe(STRIPEKEY);
  }
  if (authDataFromMobile) {
    stripePromise = loadStripe(STRIPEKEY);
  }
  const layoutType = getPlatformType();
  const [page, setPage] = React.useState(1);
  const [nameOnCard, setNameOnCard] = React.useState('');
  const [clientSecret, setClientSecret] = React.useState('');
  const [selectedPackedge, setSelectedPackege] = React.useState({});
  const [preQualData, setPreQualData] = React.useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openBankingModal,setOpeningBankingModal]=useState(false)
  const [preRefData, setPreRefData] = React.useState(true);
  const [guarantorData, setGuarantorData] = React.useState({});
  const [agreeTerms, setAgreeTerms] = React.useState(false);
  const [addOnSelected, setAddOnSelected] = React.useState(false);
  const [billlingAddress, setBillingAddress] = React.useState('');
  const [guarantorAddOnPackage, setGuarantorAddOnPackage] = React.useState({});
  const [combinedPackage, setCombinedPackage] = React.useState({});
  const [referenceOnlyPackage, setReferenceOnlyPackage] = React.useState({});
  const globalState = useSelector((state) => state?.tenant);
  const userData = useSelector((state) => state.authReducer?.profile?.data?.appUser);
  const [reload, setReload] = useState(false);
  const [guarantorPay, setGPay] = useState(false);
  const [RequestedDocuments,setRequestDoc]=useState([]);
  const [guarantor, setGuarantor] = useState({
    first_name: '',
    last_name: '',
    email: '',
    title: '',
  });
  const [renderType, setType] = useState('card');
  const [onFirstTimeCardClick, setFirstTimeClick] = useState(true);
  const [paymentMode, setPaymentMode] = useState('');
  const { colors } = useTheme();
  const { fcc_pre_reference } = preQualData;
  const [checked, setChecked] = useState(false);



  const [guarantorFormError, setGError] = useState('');

  const [showRenewPopup,setShowRenewPopup]=useState(false);

  const [shareSettings, setShareSettings] = React.useState({
    share_affordability: false,
    share_pre_qualification: false,
    share_rental_ledger: false,
    share_right_to_rent: false,
    share_yoti_id: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tenantActions.getPreQualificationStatus());
    dispatch(tenantActions.getPackedge('/pre-qualification/digital-reference-packages'));
    dispatch(tenantActions.getCodesStatus());
   
  }, [reload]);
  useEffect(() => {
    if (globalState?.pack && globalState?.pack?.length) {
      const package1 = globalState?.pack.find((x) => x.product_code === 'tenant_digital_reference');
      if (package1) {
        package1.name = 'Rent Ready Reference only';
      }

      const package2 = globalState?.pack.find(
        (x) => x.product_code === 'tenant_digital_reference_and_id_verification'
      );
      if (package2) {
        package2.name = 'Rent Ready Reference & ID Verification';
      }

      const package3 = globalState?.pack.find(
        (x) => x.product_code === 'tenant_guarantor_digital_reference'
      );
      if (package3) {
        package3.name = '';
      }
      setGuarantorAddOnPackage(package3);
      setCombinedPackage(package2);
      setReferenceOnlyPackage(package1);
    } else {
    }
  }, [globalState.pack]);


  useEffect(() => {
    if (globalState?.preQualData?.preQualStatus) {
      setPreQualData(globalState?.preQualData?.preQualStatus);
    }
    if (globalState?.preQualData?.preQualStatus.fcc_pre_reference.payment_required) {
      setPage(1);
      setPreRefData(false);
    } else {
      setPreRefData(true);
    }
    if (globalState?.sharedCodes?.share_settings) {
      setShareSettings(globalState?.sharedCodes?.share_settings);
    }
    if (globalState?.preQualData?.preQualStatus.fcc_guarantors) {
      setGuarantorData(globalState?.preQualData?.preQualStatus.fcc_guarantors);
    }
  }, [globalState]);


  useEffect(()=>{
      const {fcc_pre_reference}=preQualData;

      if(fcc_pre_reference?.documents){
        const requestedDocumentIds = fcc_pre_reference.documents.application_documents!="" && (fcc_pre_reference?.documents)?.application_documents.split(',');

        const requestedObject = requestedDocumentIds?requestedDocumentIds.map(requestedId => { 
          const match = preQualData.fccdocs.find(doc => doc.document_id === requestedId);
          return {
            document_id: requestedId,
            document_name: match ? match.document_name : requestedId
          };
        }):[];

        setRequestDoc(requestedObject);
      }


  
  },[preQualData])

  const openPreRefUrl = async (URLS) => {
    let u = URLS;
    if (Platform.OS === 'web') {
      await window.open(
        u,
        '_blank' // <- This is what makes it open in a new window.
      );
    } else {
      await Linking.openURL(u).catch((err) => console.error('An error occurred', err));
    }
    setReload(1);
  };
  const validateGuarantorForm = () => {
    if (guarantor?.title == '') {
      setGError('* Title is mandatory');
    } else if (guarantor?.first_name == '') {
      setGError('* First name is mandatory');
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(guarantor?.email)) {
      setGError('* Invalid email');
    } else {
      setGError('');
      const payload = {
        guarantors: [
          {
            firstname: guarantor?.first_name,
            lastname: guarantor?.last_name,
            title: guarantor?.title,
            email: guarantor?.email,
          },
        ],
      };
      dispatch(
        tenantActions.addGuarantorToReference(
          payload,
          fcc_pre_reference?.applicant?.applicant_id,
          (response) => {
            console.log(response);
            setReload(true);
            if (response?.data) {
            } else {
            }
          }
        )
      );
      setShowModal(false);
    }
  };
  const showGuarantorForm = () => {
    if (fcc_pre_reference.status == 'not_started') {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'You need to start your reference first, before adding guarantor!',
      });
    } else {
      setShowModal(true);
    }
  };

  const startRef = () => {
    if (fcc_pre_reference.progress?.applicanturl) {
      openPreRefUrl(fcc_pre_reference?.progress?.applicanturl);
    } else {
      dispatch(
        tenantActions.getPreRefUri((data) => {
          if (data?.data?.applicantUrl) {
            openPreRefUrl(data?.data?.applicantUrl);
          }
        })
      );
    }
  };

  const _onSave = (ID) => {
    if (!ID) {
      dispatch({
        type: types.SHOW_ALERT,
        alertType: 'error',
        text: 'Something is wrong!',
      });
    } else {
      let body = {
        ...shareSettings,
        [ID]: !shareSettings[ID],
      };
      dispatch(
        tenantActions.postCodesStatus(body, (data) => {
          console.log('saveAgentEmail', data);
          dispatch(tenantActions.getCodesStatus());
        })
      );
    }
  };

  const getTotalCost = () => {
    if (addOnSelected && selectedPackedge?.price_with_vat) {
      return (
        parseFloat(selectedPackedge?.price_with_vat) +
        parseFloat(guarantorAddOnPackage.price_with_vat)
      ).toFixed(2);
    } else if (!addOnSelected) {
      return selectedPackedge?.price_with_vat;
    } else {
      return guarantorAddOnPackage?.price_with_vat;
    }
  };

  const startPayment = () => {
    let payload = {
      ...selectedPackedge,
      addOnSelected,
      productId: selectedPackedge.product_id ? selectedPackedge.product_id : null,
    };
    dispatch(
      tenantActions.startPayment(payload, (data) => {
        if (data.status) {
          setClientSecret(data.data.client_secret);
          // setPage(3);
        } else {
          dispatch(showToaster('error', 'Failed to start payment'));
        }
      })
    );
  };
  const startPaymentGurantor = (product, isGuarantor) => {
    let payload = {
      ...product,
      productId: product?.product_id ? product?.product_id : null,
    };
    dispatch(
      tenantActions.startPayment(payload, (data) => {
        if (data.status) {
          setClientSecret(data.data.client_secret);
          if (isGuarantor) {
            setPaymentMode('card');
            setGPay(true);
            setPage(3);
          } else {
            setPage(9);
          }
        } else {
          dispatch(showToaster('error', 'Failed to start payment'));
        }
      })
    );
  };
  const themeObj = getTokens('light').colors;
  const guarantorSection = () => {
    return guarantorData.guarantor_payment_required ? (
      <>
        <BoxShadow height={200} wWidth={'344px'} bottom={'10px'}>
          <ODSText style={{ alignSelf: 'center' }} type={'button'}>
            Do you need a guarantor?
          </ODSText>
          <ODSText style={{ alignSelf: 'center' }} marginTop={10} type={'sm'}>
            If you have not met the required criteria and need a guarantor, please check the
            guarantor referencing criteria and conduct the guarantor affordability check before
            proceeding
          </ODSText>
          <View
            style={{
              marginTop: 30,
              width: 200,
              alignSelf: 'flex-end',
            }}
          >
            <ODSButton
              restStyle={{ maxWidth: '100%', marginVertical: 10 }}
              type="secondary"
              themeColor={themeObj.main.green}
              onPress={() => setPage(5)}
            >
              Start
            </ODSButton>
          </View>
        </BoxShadow>
      </>
    ) : (
      <>
        <BoxShadow wWidth={'344px'} bottom={'10px'}>
          <ODSText style={{ alignSelf: 'center' }} type={'button'}>
            Guarantor Reference
          </ODSText>

          {guarantorData?.guarantors?.length ? (
            <>
              {guarantorData?.guarantors.map((guarantor) => {
                return guarantor?.status == 'in_progress' ? (
                  <>
                    <>
                      <ODSText style={{ alignSelf: 'center' }} marginTop={20} type={'sm'}>
                        Guarantor:
                      </ODSText>
                      <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'button'}>
                        {guarantor.first_name} {guarantor.last_name} ({guarantor.email})
                      </ODSText>

                      <ODSButton
                        restStyle={{ maxWidth: '100%', marginVertical: 20 }}
                        type="secondary"
                        themeColor={themeObj.feedback.warning}
                        disabled={false}
                        onPress={() => { }}
                      >
                        In Progress
                      </ODSButton>
                    </>
                  </>
                ) : (
                  <>
                    <>
                      <ODSText style={{ alignSelf: 'center' }} marginTop={20} type={'sm'}>
                        Guarantor:
                      </ODSText>
                      <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'button'}>
                        {guarantor.first_name} {guarantor.last_name} ({guarantor.email})
                      </ODSText>

                      <ODSButton
                        rIcon={'TB-Check'}
                        restStyle={{ maxWidth: '100%', marginTop: 10 }}
                        type="secondary"
                        themeColor={themeObj.main.green}
                        disabled={false}
                        onPress={() => { }}
                      >
                        Completed
                      </ODSButton>
                    </>
                  </>
                );
              })}
            </>
          ) : (
            <>
              <ODSText style={{ alignSelf: 'center' }} marginTop={10} type={'sm'}>
                You need to add guarantor details below.
              </ODSText>
              <ODSText style={{ alignSelf: 'center' }} marginTop={20} type={'button'}>
                Reference application
              </ODSText>
              <ODSButton
                restStyle={{ maxWidth: '100%', marginVertical: 20 }}
                type="secondary"
                themeColor={themeObj.main.green}
                disabled={false}
                onPress={() => showGuarantorForm()}
              >
                Add Guarantor
              </ODSButton>
              <ODSText style={{ alignSelf: 'center' }} type={'button'}>
                Reference status
              </ODSText>
              <ODSButton restStyle={{ maxWidth: '100%', marginVertical: 20 }} disabled={true}>
                Not Started
              </ODSButton>
            </>
          )}
        </BoxShadow>
      </>
    );
  };

  const renderUploadDocs = () => {
    return (
      <View style={{ paddingHorizontal: layoutType == 'phone' ? 10 : 20 }}>
        <ODSText marginTop={10} type={'h5'}>
          Upload docs
        </ODSText>

        <View style={{ flexDirection: 'column' }}>
          <View style={{ flex: 'none' }}>
            <ODSText marginTop={10} type={'button'}>
              Tenant Reference Company
            </ODSText>

            <ODSText marginTop={10} type={'sm'}>
              In this section you can submit document directly to the{' '}
              <ODSText color="#FC4850" type={'sm'}>
                tenant reference company ONLY.
              </ODSText>
            </ODSText>

            {/* <ODSText marginTop={10} type={'button'}>
              Please note:{' '}
              <ODSText type={'sm'}>
                If you have been asked by the Agent to submit documentation, DO NOT use this
                section, please use the section Marked - "Letting Agent"
              </ODSText>
            </ODSText> */}
          </View>
          <View style={{ flex: 'none' }}>
            <View
              style={{
                marginTop: 10,
                flexDirection: 'column',
                marginBottom: 20,
              }}
            >
              {RequestedDocuments && RequestedDocuments.length ? (
                RequestedDocuments?.map((e, index) => (
                  <RequestDocs
                    item={e}
                    index={index}
                    width={'100%'}
                    flowId={props.flowId}
                    // tenant={currentTenant}
                    branch={'F0016'}
                    fcc={true}
                    fcc_applicant_id={fcc_pre_reference?.applicant?.applicant_id}
                    docLength={RequestedDocuments?.length}
                    refreshApi={() => {
                      setReload(9)
                    }}
                  />
                ))
              ) : (
                <ODSText type={'sm'}>
                  Upload boxes will appear here once fcc requests a document
                </ODSText>
              )}
            </View>
          </View>
        </View>

      </View>
    );
  };
  const ProgressData=fcc_pre_reference?.progress && fcc_pre_reference?.progress;
  const handlerData=fcc_pre_reference?.handler && (fcc_pre_reference?.handler);

  console.log(ProgressData)

  const renderOpenBankingRequest=()=>{
    return(
      <BoxShadow style={{ alignSelf: 'center',marginTop:20 }} tWidth={'70%'} wWidth={'620px'} bottom={'10px'} right={'0px'}>
      <>
        <ODSText color={colors.text.primary} style={{ alignSelf: 'center' }} type={'h5'}>
        Open Banking Request
        </ODSText>
        <ODSText color={colors.text.primary} marginTop={20} style={{ alignSelf: 'center' }} type={'md-bold'}>
        Tenant Reference Company
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={14}
          style={{ alignSelf: 'center' }}
          type={'md'}
        >Your reference company has made a request for open banking to facilitate the reference process.
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={10}
          style={{ alignSelf: 'center' }}
          type={'md'}
        >
          For more information about Open Banking please<TouchableOpacity onPress={()=>setOpeningBankingModal(true)}> <ODSText
color={themeObj.neutral[700]}
style={{ alignSelf: 'center' }}
type={'md-bold'}

> click here</ODSText></TouchableOpacity>

        </ODSText>
        <ODSButton
                  lIcon={'TB-Arrow-Right'}
                  themeColor={themeObj.main.green}
                  restStyle={{ marginTop: 20, alignSelf:'center' }}
                  type="primary"
                  disabled={false}
                  onPress={() => window.open(props?.openbankingURL,"_blank")}
                >
                 Start Open Banking
                </ODSButton>
      </>
  </BoxShadow>
    )
  }

  const renderPage1 = () => {
    return (
      <View style={{ flexDirection: "column" }}>
        <View style={{ flexDirection: layoutType=="phone"?'column':"row",flexWrap:'wrap' }}>
          <BoxShadow wWidth={'344px'} bottom={'10px'} right={'19px'}>
            <ODSText style={{ alignSelf: 'center' }} type={'h5'}>
              Rent Ready Reference
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              Give yourself a head start in the rental process by confirming you meet the required
              criteria to become a Rent Ready Tenant.
            </ODSText>
            <ODSText
              color={themeObj.neutral[700]}
              marginTop={14}
              style={{ alignSelf: 'center' }}
              type={'md'}
            >
              Powered By
            </ODSText>
            <ImageBackground
              source={require('./../../assets/images/Image.png')}
              style={{
                height: 89,
                width: 238,
              }}
              resizeMode={'cover'}
            />
            <View
              style={{
                width: '100%',
                height: 1,
                backgroundColor: themeObj.neutral[300],
                marginBottom: 20,
              }}
            ></View>

            {!globalState?.preQualData?.preQualStatus.fcc_pre_reference?.payment_required ? (
              <>
                <ODSText color={themeObj.feedback.error[700]} type={'button'}>
                  Expiry date:
                </ODSText>
                <ODSText marginTop={10} color={themeObj.feedback.error[700]} type={'md'}>
                  {globalState?.preQualData?.preQualStatus.fcc_pre_reference?.RentReady && globalState?.preQualData?.preQualStatus.fcc_pre_reference?.RentReady?.DateExpiry!="" 
                    ? moment(globalState?.preQualData?.preQualStatus.fcc_pre_reference?.RentReady?.DateExpiry)
                      .format('DD-MM-YYYY')
                    : ''}
                </ODSText>
                <ODSButton
                  rIcon={'TB-Rotating'}
                  restStyle={{ maxWidth: '100%', marginTop: 20 }}
                  type="secondary"
                  themeColor={themeObj.main.green}
                  disabled={false}
                  onPress={() => {
                    setShowRenewPopup(true)
                  }}
                >
                  Renew
                </ODSButton>
              </>
            ) : (
              <>
                <ODSButton
                  restStyle={{ maxWidth: '100%' }}
                  type="primary"
                  themeColor={themeObj.main.green}
                  disabled={false}
                  onPress={() => setPage(2)}
                >
                  Get started
                </ODSButton>

                <ODSText
                  color={themeObj.neutral[700]}
                  marginTop={20}
                  textAlign={'center'}
                  type={'md'}
                >
                  When you press start you’ll be redirected to our referencing partner FCC Paragon.
                </ODSText>
              </>
            )}

          </BoxShadow>
          {!globalState?.preQualData?.preQualStatus.fcc_pre_reference.payment_required ? (
            <>
              <>

              </>
              <BoxShadow wWidth={'344px'} bottom={'10px'} right={'19px'}>
                <ODSText
                  color={themeObj.neutral[700]}
                  marginBottom={14}
                  style={{ alignSelf: 'center' }}
                  type={'md'}
                >
                  Upon clicking start you will be directed to the FCC portal. if you do not complete
                  your application, you can click continue anytime and you can pick up where you left
                  off
                </ODSText>
                {fcc_pre_reference?.status == 'in_progress' ? (
                  <>
                    <ODSText style={{ alignSelf: 'center' }} type={'button'}>
                      Reference status
                    </ODSText>
                    <ODSButton
                      restStyle={{ maxWidth: '100%', marginVertical: 20 }}
                      type="secondary"
                      themeColor={themeObj.feedback.warning}
                      disabled={false}
                      onPress={() => { window.open(fcc_pre_reference?.progress?.applicanturl,"_blank")}}
                    >
                      In Progress
                    </ODSButton>
                    <View style={{marginBottom:32,marginTop:12,borderWidth:1,borderColor:'#d0d0d0',borderRadius:12,padding:12}}>
          <ODSText type='sm' color={colors.text.secondary}>Reference Handler:<ODSText type='sm-bold' marginLeft={4} marginTop={8} color={colors.text.secondary}>{handlerData && handlerData?.title} {handlerData && handlerData?.first_name} {handlerData && handlerData?.last_name} </ODSText></ODSText>
          <ODSText type='sm' marginTop={8} color={colors.text.secondary}>Tel:<ODSText type='sm-bold' marginLeft={4} marginTop={8} color={colors.text.secondary}>{handlerData && handlerData?.phone_number}</ODSText></ODSText>
          <ODSText type='sm' marginTop={8} color={colors.text.secondary}>Ref:<ODSText type='sm-bold' marginLeft={4} marginTop={8} color={colors.text.secondary}>{fcc_pre_reference?.applicant?.applicant_id}</ODSText></ODSText>
        </View>
         {ProgressData &&  <>
                    <AccordianCard
          title={'Employment'}
          status={
            ProgressData?.current_employment_status
              ? ProgressData?.current_employment_status
              : 'Not Started'
          }
          comments={
            ProgressData?.current_employment_comments
              ? ProgressData?.current_employment_comments
              : 'N/A'
          }
          lastUpdate={
            ProgressData?.current_employment_date_last_updated
              ? moment(ProgressData?.current_employment_date_last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Credit Search'}
          status={
            ProgressData?.search_details_status
              ?  ProgressData?.search_details_status
              : 'Not Started'
          }
          comments={
            ProgressData?.search_details_comments
              ?  ProgressData?.search_details_comments
              : 'N/A'
          }
          lastUpdate={
            ProgressData?.search_details_date_last_updated
              ? moment(ProgressData?.search_details_date_last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Living Status'}
          status={
            ProgressData?.current_living_status
              ?  ProgressData?.current_living_status
              : 'Not Started'
          }
          comments={
            ProgressData?.current_living_comments
              ?  ProgressData?.current_living_comments
              : 'N/A'
          }
          lastUpdate={
            ProgressData?.current_living_date_last_updated
              ? moment(ProgressData?.current_living_date_last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
        <AccordianCard
          title={'Tenant Search'}
          status={
            ProgressData?.tenant_database_status
              ? ProgressData?.tenant_database_status
              : 'Not Started'
          }
          comments={
            ProgressData?.tenant_database_comments
              ? ProgressData?.tenant_database_comments
              : 'N/A'
          }
          lastUpdate={
            ProgressData?.tenant_database_date_last_updated
              ? moment(ProgressData?.tenant_database_date_last_updated).format('Do MMM YYYY')
              : 'N/A'
          }
        />
      
        </>}
                  </>
                ) : fcc_pre_reference?.status == 'completed' ? (
                  <>
                    <ODSText style={{ alignSelf: 'center' }} type={'button'}>
                      Reference status
                    </ODSText>

                    <ODSButton
                      rIcon={'TB-Check'}
                      restStyle={{ maxWidth: '100%', marginTop: 10 }}
                      type="secondary"
                      themeColor={themeObj.main.green}
                      disabled={false}
                      onPress={() => { }}
                    >
                      Completed
                    </ODSButton>

                    <>
                      <ODSText style={{ alignSelf: 'center' }} marginTop={20} type={'button'}>
                        Reference Application
                      </ODSText>
                      <ODSButton
                        rIcon={'TB-Check'}
                        restStyle={{ maxWidth: '100%', marginTop: 10 }}
                        type="secondary"
                        themeColor={themeObj.main.green}
                        disabled={false}
                        onPress={() => { }}
                      >
                        Completed
                      </ODSButton>
                    </>
                    <View
                      style={{
                        height: 0.5,
                        marginTop: 32,
                        backgroundColor: COLORS.GREY1,
                        width: '100%',
                      }}
                    />
                    <View
                      style={{
                        flexDirection: 'row',
                        marginTop: 30,
                        alignItems: 'center',
                      }}
                    >
                      <View style={{ flex: 0.6 }}>
                        <ODSText style={{ alignSelf: 'center' }} type={'button'}>
                          Rent Ready doc
                        </ODSText>
                      </View>
                      <View style={{ flex: 0.4 }}>
                        <ODSBadges
                          restStyle={{ alignSelf: 'flex-end' }}
                          type={
                            globalState?.preQualData?.preQualStatus.fcc_pre_reference.status ==
                              'completed'
                              ? 'Completed'
                              : 'In Progress'
                          }
                        >
                          {globalState?.preQualData?.preQualStatus.fcc_pre_reference.status ==
                            'completed'
                            ? 'Completed'
                            : 'Interim'}
                        </ODSBadges>
                      </View>
                    </View>
                    <ODSButton
                      lIcon={'TB-Arrow-Right'}
                      restStyle={{ maxWidth: '100%', marginTop: 30 }}
                      themeColor={themeObj.main.green}
                      disabled={false}
                      onPress={() => {
                        if (fcc_pre_reference?.report_content) {
                          openPreRefUrl(fcc_pre_reference?.report_content);
                        }
                      }}
                    >
                      View
                    </ODSButton>

                    <View
                      style={{
                        flexDirection: 'row',
                        marginTop: 30,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {/* <WideButton
                      childText={shareSettings.share_pre_qualification ? 'share' : 'Unshare'}
                      buttonType={shareSettings.share_pre_qualification ? 'solid' : 'stripe'}
                      isLGreen
                      backgroundColor={COLORS.LGREEN}
                      gradiant
                      stripetextColor={'black'}
                      textSize={16}
                      height={'40px'}
                      width={'136px'}
                      alignSelf={'end'}
                      icon={
                        <Entypo
                          style={{
                            marginRight: 10,
                            marginLeft: 15,
                          }}
                          name={shareSettings.share_pre_qualification ? 'eye-with-line' : 'eye'}
                          size={16}
                          color={shareSettings.share_pre_qualification ? 'white' : COLORS.LGREEN}
                        />
                      }
                      onPress={() => {
                        _onSave('share_pre_qualification');
                      }}
                    />
                    <WideButton
                      childText={'View'}
                      gradiant
                      isLGreen
                      height={'40px'}
                      width={'136px'}
                    
                      ricon={
                        <MaterialIcons name="keyboard-arrow-right" size={18} color={'white'} />
                      }
                    /> */}
                    </View>
                  </>
                ) : (
                  <>
                    <ODSText style={{ alignSelf: 'center' }} type={'button'}>
                      Reference application
                    </ODSText>
                    <ODSButton
                      restStyle={{ maxWidth: '100%', marginVertical: 10 }}
                      type="secondary"
                      themeColor={themeObj.main.green}
                      disabled={false}
                      onPress={() => startRef()}
                    >
                      Start Application
                    </ODSButton>
                    <ODSText style={{ alignSelf: 'center' }} type={'button'}>
                      Reference status
                    </ODSText>

                    <ODSButton restStyle={{ maxWidth: '100%', marginTop: 10 }} disabled={true}>
                      Not Started
                    </ODSButton>
                  </>
                )}
              </BoxShadow>
              {guarantorSection()}
          
            </>
          ) : null}
        </View>
      
       {ProgressData &&
       <>
       {props?.openbankingURL&&renderOpenBankingRequest()}
       <BoxShadow wWidth={'60%'} style={{marginTop:30,alignSelf:'center'}}> <View style={{marginTop:20}}> {renderUploadDocs()}</View></BoxShadow> 
       </>}
        <ODSButton
          rIcon={'TB-Share'}
          restStyle={{ marginTop: 20, alignSelf: 'center', width: 300,alignSelf:'center' }}
          type="secondary"
          disabled={false}
          onPress={() => navigate('Document Share Center', { activeIndex: 2 })}
        >
          My Share Centre
        </ODSButton>
      </View>

    );
  };
  

  const renderPage2 = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <ODSText marginBottom={20} style={{ alignSelf: 'center' }} type={'h5'}>
          Choose Package
        </ODSText>

        <View style={{ flexDirection: layoutType !== 'phone' ? 'row' : 'column' }}>
          <View style={{ flexDirection: 'column' }}>
            <TouchableOpacity
              onPress={() => {
                if (selectedPackedge?.product_id == combinedPackage?.product_id) {
                  setSelectedPackege('');
                } else {
                  setSelectedPackege(combinedPackage);
                }
              }}
              style={{
                flexDirection: 'column',
                width: layoutType == 'phone' ? '100%' : '344px',
                marginRight: layoutType === 'web' ? 20 : 0,
                marginBottom: 10,
              }}
            >
              <BoxShadow
                style={{
                  borderWidth: selectedPackedge?.product_id == combinedPackage?.product_id ? 1 : 0,
                  borderColor: COLORS.LGREEN,
                }}
                bottom={'10px'}
                right={'19px'}
              >
                <View style={{ alignSelf: 'flex-end' }}>
                  <Ionicons
                    name={
                      selectedPackedge?.product_id === combinedPackage?.product_id
                        ? 'md-radio-button-on'
                        : 'md-radio-button-off'
                    }
                    size={24}
                    color={
                      selectedPackedge?.product_id === combinedPackage?.product_id
                        ? COLORS.LGREEN
                        : COLORS.BLACK
                    }
                  />
                  <ODSText marginTop={20} style={{ alignSelf: 'center' }} type={'button'}>
                    {combinedPackage.name}
                  </ODSText>
                  <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
                    £{combinedPackage?.price_with_vat} (inc. VAT)
                  </ODSText>

                  <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
                    Prove you meet the referencing criteria by securing a Rent Ready Reference and
                    verify your ID to become a Rent Ready Tenant.
                  </ODSText>
                </View>
              </BoxShadow>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            onPress={() => {
              if (selectedPackedge?.product_id == referenceOnlyPackage?.product_id) {
                setSelectedPackege('');
              } else {
                setAddOnSelected(false)
                setSelectedPackege(referenceOnlyPackage);
              }
            }}
            style={{
              flexDirection: 'column',
              width: layoutType == 'phone' ? '100%' : '344px',
              marginRight: layoutType === 'web' ? 20 : 0,
              marginBottom: 10,
              alignItems: 'center',
            }}
          >
            <BoxShadow
              style={{
                borderWidth:
                  selectedPackedge?.product_id == referenceOnlyPackage?.product_id ? 1 : 0,
                borderColor: COLORS.LGREEN,
              }}
              wWidth={'344px'}
              bottom={'10px'}
              right={'19px'}
            >
              <View style={{ alignSelf: 'flex-end' }}>
                <Ionicons
                  name={
                    selectedPackedge?.product_id === referenceOnlyPackage?.product_id
                      ? 'md-radio-button-on'
                      : 'md-radio-button-off'
                  }
                  size={24}
                  color={
                    selectedPackedge?.product_id === referenceOnlyPackage?.product_id
                      ? COLORS.LGREEN
                      : COLORS.BLACK
                  }
                />
              </View>
              <ODSText marginTop={20} style={{ alignSelf: 'center' }} type={'button'}>
                {referenceOnlyPackage.name}
              </ODSText>
              <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
                £{referenceOnlyPackage?.price_with_vat} (inc. VAT)
              </ODSText>

              <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
                For tlyfe users who are already ID verified you can simply opt for the "Rent Ready
                Reference only" route and use this, with your existing verified ID to become a Rent
                Ready Tenant
              </ODSText>
            </BoxShadow>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <CheckBox
            checked={addOnSelected}
            onIconPress={() => setAddOnSelected(!addOnSelected)}
            // disabled={selectedPackedge?.product_id == referenceOnlyPackage?.product_id}
            checkedColor={COLORS.LGREEN}
            uncheckedColor={COLORS.GREY1}
            containerStyle={{ padding: 0 }}
          />

          <View style={{ width: '80%' }}>
            <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'button'}>
              Add Guarantor Reference: £{guarantorAddOnPackage.price_with_vat} (inc. VAT)
            </ODSText>
            <ODSText marginTop={10} style={{ alignSelf: 'center' }} type={'sm'}>
              If, having read the referencing criteria, you believe you need a guarantor, please
              select this option
            </ODSText>
          </View>
        </View>
        {/* <View style={{ marginTop:24,alignSelf:'center',width: '100%',borderRadius: 4, gap: 12,padding: 16, backgroundColor: colors.elements['color-bg'], flexDirection: 'row', borderWidth: 1, borderColor: colors.elements['color-light']}}>
                    <ODSIcon icon="TB-Info-Outline" size={24} color={colors.main.blue[500]} />
                    <ODSText type='md-bold' color={colors.text.primary}> Please note: <ODSText type="md" color={colors.text.secondary} >If you use a voucher to pay for this service, the guarantor reference will not be included.</ODSText></ODSText>
                </View> */}
        <View
          style={{
            width: '100%',
            height: 1,
            backgroundColor: COLORS.GREY1,
            marginVertical: 20,
          }}
        />
        <ODSText marginTop={20} style={{ alignSelf: 'center' }} type={'sm'}>
          Before purchasing the Rent Ready Reference, you must read and agree to the terms and
          conditions below:
        </ODSText>

        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            marginTop: 12,
          }}
        >
          <CheckBox
            checked={agreeTerms}
            onIconPress={() => setAgreeTerms(!agreeTerms)}
            checkedColor={COLORS.LGREEN}
            uncheckedColor={COLORS.GREY1}
            containerStyle={{ padding: 0 }}
          />
          <View style={{ width: '80%' }}>
            <TouchableOpacity
              onPress={() =>
                window.open(
                  'https://fccparagon.com/wp-content/uploads/2023/03/prequalificationreferencetermsandconditions.pdf',
                  '_blank'
                )
              }
            >
              <ODSText textDecorationLine={'underline'} style={{ alignSelf: 'center' }} type={'sm'}>
                I agree to the terms and conditions of FCC Paragon Rent Ready Reference
              </ODSText>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            marginTop: 20,
            width: 200,
            alignSelf: 'flex-end',
          }}
        >
          <ODSButton
            lIcon={'TB-Arrow-Right'}
            restStyle={{ alignSelf: 'center', width: '100%' }}
            type="primary"
            disabled={false}
            onPress={() => {
              if (!agreeTerms) {
                dispatch(showToaster('error', 'Accept the terms'));
              } else if (selectedPackedge?.product_id) {
                // startPayment();
                setPage(9);
              } else {
                dispatch(showToaster('error', 'Please select one package to proceed!'));
              }
            }}
          >
            Checkout
          </ODSButton>
      
        </View>
        <View style={{ marginTop:24,alignSelf:'center',width: '100%',borderRadius: 4, gap: 12,padding: 16, backgroundColor: colors.elements['color-bg'], flexDirection: 'row', borderWidth: 1, borderColor: colors.elements['color-light']}}>
                    <ODSIcon icon="TB-Info-Outline" size={24} color={colors.main.blue[500]} />
                    <ODSText type="md" color={colors.text.secondary} >If you have a voucher you will have the option to use it after clicking checkout</ODSText>
                </View>
      </View>
    );
  };
  const renderPaymentMode = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <ODSText color={themeObj.neutral[700]} type={'h5'} marginBottom={20}>
          Choose a payment mode
        </ODSText>

        <View style={{ flexDirection: layoutType === 'web' ? 'row' : 'column',gap:20 }}>
          <View
            id="idverificationpack1"
            style={{ flexDirection: layoutType == 'web' ? 'row' : 'column' }}
          >
            <TouchableOpacity
              id={'idverificationpack1'}
              testID={'idverificationpack1'}
              onPress={() => {
                setType('card');
              }}
              style={{ height: 200 }}
            >
              <BoxShadow
                id={'idverificationpack1'}
                style={{
                  borderWidth: renderType == 'card' ? 1 : 0,
                  borderColor: COLORS.LGREEN,
                }}
                bottom={'10px'}
                right={'19px'}
              >
                <ODSText
                  textAlign={'flex-start'}
                  color={themeObj.neutral[700]}
                  style={{ marginTop: 10 }}
                  marginTop={20}
                  type={'button'}
                >
                  <FontAwesome
                    name={'credit-card'}
                    size={16}
                    color={COLORS.LGREEN}
                    style={{ marginRight: 10 }}
                  />
                  Pay by card
                </ODSText>
                <Image
                  source={require('../../assets/images/infohub/cards.png')}
                  style={{ width: 150, height: 40, marginTop: 10, resizeMode: 'contain' }}
                />
                <ODSText
                  textAlign={'flex-start'}
                  color={themeObj.neutral[700]}
                  style={{ marginTop: 10 }}
                  marginTop={20}
                  type={'button'}
                >
                  Total cost £{getTotalCost()}
                </ODSText>
              </BoxShadow>
            </TouchableOpacity>
          </View>

          <TouchableOpacity
            id={'idverificationpack2'}
            onPress={() => {
              setType('agent voucher');
            }}
            style={{
              flexDirection: 'column',
              width: layoutType == 'phone' ? '100%' : '344px',
              marginRight: layoutType === 'web' ? 20 : 0,
              marginBottom: 10,
            }}
          >
            <BoxShadow
              style={{
                borderWidth: renderType == 'agent voucher' ? 1 : 0,
                borderColor: COLORS.LGREEN,
              }}
              bottom={'10px'}
              right={'19px'}
            >
              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                style={{ marginTop: 10 }}
                marginTop={20}
                type={'button'}
              >
                <FontAwesome
                  name={'tag'}
                  size={16}
                  color={COLORS.LGREEN}
                  style={{ marginRight: 10 }}
                />
                Pay by agent voucher
              </ODSText>
              <ODSText color={themeObj.neutral[700]} type={'sm'}>
                By paying with agent voucher, you also get the rent ready reference included
              </ODSText>
              <ODSText
                textAlign={'flex-start'}
                color={themeObj.neutral[700]}
                style={{ marginTop: 10 }}
                marginTop={20}
                type={'button'}
              >
                Total cost £0
              </ODSText>
              <View style={{ flexDirection: 'row', paddingVertical: 10, paddingRight: 20 }}>
                        <ODSCheckbox onPress={() => setChecked(!checked)} checked={checked} />
                        <ODSText marginLeft={10} type='xs' color={colors.text.primary}>By Using this voucher,you agree to the terms of the voucher as listed in our {`\n`}
                            <TouchableOpacity onPress={() => window.open('https://tlyfe.co.uk/legal-bits?to=t%2fc')}><ODSText textDecorationLine='underline' type='xs' color={colors.main.blue[500]}> T&C</ODSText></TouchableOpacity> and <TouchableOpacity onPress={() => window.open('https://tlyfe.co.uk/legal-bits?to=t%2fc')}><ODSText textDecorationLine='underline' type='xs' color={colors.main.blue[500]}> Privacy policy </ODSText></TouchableOpacity></ODSText>
                    </View>
            </BoxShadow>
          </TouchableOpacity>
        </View>
        {(renderType == 'agent voucher' && !checked) && <ODSText type='xs' align='flex-end' color={colors.feedback.error[500]} marginTop={10}>Please agree to the terms of the voucher before continuing</ODSText>}

        <View
          style={{
            marginTop: 20,
            width: 200,
            alignSelf: 'flex-end',
          }}
        >
          <ODSButton
            lIcon={'TB-Arrow-Right'}
            restStyle={{ width: '100%', marginTop: 20, alignSelf: 'center' }}
            disabled={(renderType == 'agent voucher' && !checked)}
            onPress={() => {
              onFirstTimeCardClick && renderType == 'card' && startPayment();
              setPaymentMode(renderType);
              renderType == 'card' && setFirstTimeClick(false);
              setPage(3);
            }}
          >
            Continue
          </ODSButton>
        </View>
      </View>
    );
  };

  const renderPage3 = () => {
    return (
      <View style={{ flexDirection: 'column' }}>
        <ODSText color={themeObj.neutral[700]} type={'h5'} marginBottom={20}>
          Checkout
        </ODSText>

        <View
          style={{
            flexDirection: 'column',
            width: layoutType == 'phone' ? '100%' : '344px',
          }}
        >
          <BoxShadow
            wWidth={'344px'}
            bottom={'10px'}
            right={'19px'}
            style={{
              marginBottom: 24,
              padding: 25,
              borderColor: COLORS.LGREEN,
              borderWidth: 1,
            }}
          >
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {selectedPackedge?.name && (
                <>
                  <ODSText color={themeObj.neutral[700]} type={'sm'}>
                    {selectedPackedge?.name}
                  </ODSText>
                  <ODSText color={themeObj.neutral[700]} type={'sm'}>
                    £{selectedPackedge?.price_with_vat}
                  </ODSText>
                </>
              )}
            </View>
            {addOnSelected ? (
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <ODSText color={themeObj.neutral[700]} type={'sm'}>
                  Guarantor Reference
                </ODSText>
                <ODSText color={themeObj.neutral[700]} type={'sm'}>
                  £{guarantorAddOnPackage.price_with_vat}
                </ODSText>
              </View>
            ) : null}
            <ODSText
              textAlign={'flex-start'}
              color={themeObj.neutral[700]}
              style={{ marginTop: 10 }}
              marginTop={20}
              type={'button'}
            >
              Total cost: £{getTotalCost() ? getTotalCost() : ''}
            </ODSText>
          </BoxShadow>
          {!guarantorPay && (
            <View
              style={{
                flex: 1,
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                marginBottom: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  onFirstTimeCardClick && startPayment();
                  setPaymentMode(paymentMode == 'card' ? 'agent voucher' : 'card');
                  setFirstTimeClick(false);
                }}
                style={{
                  flexDirection: 'row',
                  marginVertical: 10,
                  paddingHorizontal: 15,
                  paddingVertical: 5,
                  borderWidth: 1,
                  borderColor: COLORS.LGREEN,
                  borderRadius: 1000,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FontAwesome
                  name={paymentMode == 'card' ? 'tags' : 'credit-card'}
                  size={16}
                  color={COLORS.LGREEN}
                />
                <Typography left={10} size={14} color={'black'}>
                  Pay by {paymentMode == 'card' ? 'agent voucher' : 'card'}
                </Typography>
              </TouchableOpacity>
            </View>
          )}
          {paymentMode == 'agent voucher' && (
            <PayByVoucher
              onSuccessToken={(success) => {
                if (success) {
                  setPage(4);
                } else {
                  setPage(6);
                }
              }}
            />
          )}
          {authDataFromMobile && paymentMode == 'card' && clientSecret ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
              }}
            >
              <CheckoutForm
                theme={themeObj.main}
                onSuccessToken={(success) => {
                  if (success) {
                    setPage(4);
                  } else {
                    setPage(6);
                  }
                }}
              />
            </Elements>
          ) : null}
          {hasUserSavedCookies && paymentMode == 'card' && clientSecret ? (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
              }}
            >
              <CheckoutForm
                onSuccessToken={(success) => {
                  if (success) {
                    setPage(4);
                  } else {
                    setPage(6);
                  }
                }}
              />
            </Elements>
          ) : null}
        </View>
      </View>
    );
  };

  const renderPage6 = () => {
    return (
      <View
        style={{
          flexDirection: 'column',
          width: layoutType == 'phone' ? '100%' : '344px',
        }}
      >
        <Image
          source={require('./../../assets/images/failed.png')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
          marginTop={20}
        >
          Payment Failed!
        </ODSText>

        <ODSButton
          rIcon={'TB-Arrow-Left'}
          restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
          type="secondary"
          themeColor={themeObj.main.salmon}
          disabled={false}
          onPress={() => {
            setPage(2);
          }}
        >
          Back
        </ODSButton>
      </View>
    );
  };
  const renderImagesSection = () => {
    return (
      <>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: 10,
          }}
        >
          <Image
            source={require('./../../assets/images/yoti.svg')}
            resizeMode="contain"
            style={{
              height: 38,
              width: 86,
              marginTop: 10,
            }}
          />
          <View style={{ height: 40, width: 2, backgroundColor: 'black' }} />
          <Image
            source={require('./../../assets/images/tlyfe.png')}
            resizeMode="contain"
            style={{
              height: 38,
              width: 86,

              marginTop: 10,
            }}
          />
        </View>
        <Typography top={'24px'} size={'18px'} bold={'700'} color={'#4f4f4f'}>
          Digital Identity Checks
        </Typography>
        <Typography top={'10px'} size={'16px'} bold={'700'} color={'#4f4f4f'}>
          Smooth experience with YOTI and tlyfe
        </Typography>
        <Typography top={'14px'} size={'13px'} bold={'300'} color={'#4f4f4f'}>
          Yoti is the UK’s first certified Identity Services Provider (IDSP) under the Digital
          Identity and Attributes Trust Framework.
        </Typography>
        <Image
          source={require('./../../assets/images/gov.png')}
          resizeMode="contain"
          style={{
            height: 48,
            width: 100,
            alignSelf: 'center',
            marginTop: 10,
          }}
        />
      </>
    );
  };
  const renderPage4 = () => {
    return !selectedPackedge?.product_id ? (
      <View
        style={{
          flexDirection: 'column',
          width: layoutType == 'phone' ? '100%' : '344px',
        }}
      >
        <Image
          source={require('./../../assets/images/Progress.svg')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
          marginTop={20}
        >
          Payment successful!
        </ODSText>
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          textAlign={'center'}
          type={'sm'}
          marginTop={30}
        >
          Your guarantor will receive an email to complete their application with our referencing
          partner, FCC Paragon
        </ODSText>
        <ODSButton
          lIcon={'TB-Arrow-Right'}
          restStyle={{ width: 300, marginTop: 20, alignSelf: 'center' }}
          type="secondary"
          disabled={false}
          onPress={() => {
            setPage(1);
            setPreRefData(true);
            setReload(6);
          }}
        >
          Rent Ready Reference
        </ODSButton>
      </View>
    ) : (
      <View
        style={{
          flexDirection: 'column',
          width: '344px',
        }}
      >
        <Image
          source={require('./../../assets/images/Progress.svg')}
          resizeMode="contain"
          style={{
            height: 100,
            width: 100,
            alignSelf: 'center',
            marginTop: 45,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          type={'button'}
          marginTop={20}
        >
          Payment successful!
        </ODSText>
        <ODSButton
          lIcon={'TB-Arrow-Right'}
          restStyle={{ width: 300, marginTop: 40, alignSelf: 'center' }}
          type="secondary"
          disabled={false}
          onPress={() => {
            setPage(1);
            setPreRefData(true);
            setReload(4);
          }}
        >
          Continue
        </ODSButton>

        <ImageBackground
          resizeMode="cover"
          source={require('./../../assets/images/Image.png')}
          style={{
            height: 60,
            width: 200,
            alignSelf: 'center',
            marginTop: 30,
          }}
        />
        <ODSText
          textAlign={'center'}
          color={themeObj.neutral[700]}
          style={{ alignSelf: 'center' }}
          textAlign={'center'}
          type={'sm'}
          marginTop={30}
        >
          When you press start you’ll be redirected to our Id Verification partner YOTI
        </ODSText>
      </View>
    );
  };

  const renderPage5 = () => {
    return (
      <View>
        <ODSText type={'h5'} marginTop={20}>
          Guarantor Rent Ready Reference
        </ODSText>
        <ODSText color={themeObj.neutral[700]} type={'sm'} marginTop={10}>
          Total Cost : £12
        </ODSText>
        <ODSText type={'sm'} marginTop={20}>
          A guarantor will guarantee your rental payments and any other terms contained within your
          tenancy agreement, for the full term of your tenancy. You should make sure they are aware
          of this before proceeding.
        </ODSText>

        <BoxShadow style={{ flexDirection: 'row', marginTop: 20 }}>
          <AntDesign name="infocirlce" size={24} color={COLORS.LGREEN} />
          <ODSText style={{ alignSelf: 'center' }} type={'sm'} marginLeft={10}>
            The content of the reference remains the property of the guarantor and they may choose
            not to share the results with you.
          </ODSText>
        </BoxShadow>
        <View
          style={{
            width: 300,
            // alignSelf: 'flex-end',
          }}
        >
          <ODSButton
            lIcon={'TB-Arrow-Right'}
            restStyle={{ width: 300, marginTop: 40, alignSelf: 'center' }}
            type="secondary"
            disabled={false}
            onPress={() => {
              const package3 = globalState?.pack.find(
                (x) => x.product_code === 'tenant_guarantor_digital_reference'
              );
              console.log('package3package3', package3);
              Promise.resolve()
                .then(() => setAddOnSelected(true))
                .then(() => setSelectedPackege(null))
                .then(() => setGuarantorAddOnPackage(package3))
                .then(() => startPaymentGurantor(package3, true));
            }}
          >
            Continue
          </ODSButton>
        </View>
      </View>
    );
  };

  return (
    <>
      <Wrapper>
        {page == 1
          ? renderPage1()
          : page == 2
            ? renderPage2()
            : page == 3
              ? renderPage3()
              : page == 4
                ? renderPage4()
                : page == 5
                  ? renderPage5()
                  : page == 6
                    ? renderPage6()
                    : renderPaymentMode()}

        <Modal
          isVisible={showModal}
          transparent={true}
          coverScreen={false}
          hasBackdrop={false}
          backdropOpacity={0}
          onBackdropPress={() => setShowModal(false)}
          style={{
            margin: 0,
            width: '100%',
            flex: 1,
            backgroundColor: '#00000080',
            paddingVertical: layoutType == 'phone' ? '5%' : '1%',
            paddingHorizontal: layoutType == 'phone' ? '8%' : '3%',
          }}
        >
          <View
            style={{
              width: layoutType == 'phone' ? '365px' : '450px',
              backgroundColor: '#FAFAFA',
              borderRadius: 20,
              overflow: 'hidden',
              alignSelf: 'center',
              padding: layoutType == 'phone' ? '8%' : '2%',
            }}
          >
            <ODSText
              style={{ alignSelf: 'center' }}
              alignSelf={'center'}
              marginTop={10}
              type={'h5'}
            >
              Guarantor Details
            </ODSText>
            <ODSText
              style={{ alignSelf: 'center' }}
              alignSelf={'center'}
              marginTop={20}
              type={'button'}
            >
              Submit guarantor details before starting application
            </ODSText>

            <View style={{ width: layoutType !== 'phone' ? '100%' : '100%', marginTop: 30 }}>
              <Typography size={18} align={'justify'} color={COLORS.BLACK}>
                Title*
              </Typography>
              <DropDownPicker
                items={openFlow.titles.map((salutation) => {
                  return {
                    value: salutation,
                    label: salutation,
                  };
                })}
                placeholder="Title"
                value={guarantor?.title}
                onChangeItem={(e) =>
                  setGuarantor((prevState) => ({
                    ...prevState,
                    title: e.value,
                  }))
                }
                dropDownStyle={{ backgroundColor: 'white' }}
                zIndex={1001}
                bColor={COLORS.LGREEN}
              />
            </View>
            <View style={{ height: 10 }} />
            <CustomTextInput
              textSize={18}
              width={'100%'}
              title="First name*"
              onChangeText={(e) =>
                setGuarantor((prevState) => ({
                  ...prevState,
                  first_name: e,
                }))
              }
              bColor={COLORS.LGREEN}
            />
            <CustomTextInput
              textSize={18}
              width={'100%'}
              title="Last name*"
              onChangeText={(e) =>
                setGuarantor((prevState) => ({
                  ...prevState,
                  last_name: e,
                }))
              }
              bColor={COLORS.LGREEN}
            />
            <CustomTextInput
              textSize={18}
              width={'100%'}
              title="Email*"
              onChangeText={(e) =>
                setGuarantor((prevState) => ({
                  ...prevState,
                  email: e,
                }))
              }
              bColor={COLORS.LGREEN}
            />
            <Typography bold={'300'} align={'justify'} size={14} color={'red'} top={8}>
              {guarantorFormError}
            </Typography>
            <ODSButton
              restStyle={{ maxWidth: '100%', marginVertical: 20 }}
              themeColor={themeObj.main.green}
              disabled={false}
              onPress={() => validateGuarantorForm()}
            >
              Submit
            </ODSButton>
            <ODSButton
              restStyle={{ maxWidth: '100%' }}
              type="secondary"
              themeColor={themeObj.main.salmon}
              disabled={false}
              onPress={() => setShowModal(false)}
            >
              Close
            </ODSButton>
          </View>
        </Modal>
        <ODSModal
          hasBackdrop
          visible={showRenewPopup}
          onBackdropPress={() => {
            setShowRenewPopup(false);
          }}
          backdropOpacity={0}
          onBackdropPress={() => setShowRenewPopup(false)}
        >
          <View style={{ borderRadius: 12, backgroundColor: 'white', width: layoutType == "phone" ? '90%' : 400, padding: 20, alignSelf: 'center' }}>
            <ODSText textAlign="center" type="h4" color={colors.text.secondary}>
              Renew Reference
            </ODSText>
            <ODSText type="md-bold" color={colors.text.secondary} marginTop={20} textAlign={'left'}>Please Note:</ODSText>
            <ODSText type="md" color={colors.text.secondary} marginTop={12} textAlign={'left'}>You are about to buy a new Rent Ready Reference.</ODSText>
            <ODSText type="md" color={colors.feedback.error[700]} marginTop={12} textAlign={'left'}>Your current Rent Ready Reference will be deleted.</ODSText>
            <ODSText type="md" color={colors.text.secondary} marginTop={12} textAlign={'left'}>If you wish to keep a copy of the current Rent Ready Reference, please click back and download a copy now. You can upload this back into your My Docs section.</ODSText>
<ODSText type="md" color={colors.text.secondary} marginTop={12} textAlign={'left'}>If you wish to proceed, please click OK.</ODSText>
            <View style={{ marginTop: 16,flexDirection:'row',justifyContent:'space-between' }}>
             
              <ODSButton
                themeColor={colors.main.green}
                type="secondary"
                disabled={false}
                restStyle={{width:'45%'}}
                onPress={()=>setShowRenewPopup(false)}
                // onPress={_onResetPassword}
              >
                Back
              </ODSButton>
              <ODSButton
                themeColor={colors.main.green}
                type="Primary"
                disabled={false}
                restStyle={{width:'45%'}}
                onPress={()=>{
                  setShowRenewPopup(false)
                  setPage(2);
                }}
              >
                OK
              </ODSButton>
            </View>
          </View>
        </ODSModal>

        <ODSModal
          visible={openBankingModal}
          backdropOpacity={0}
          onBackdropPress={() => setOpeningBankingModal(false)}
        >
          <View
            style={{
              width: layoutType == 'phone' ? '95%' : '60%',
              height: layoutType == 'phone' && 680,
              alignSelf: 'center',
              backgroundColor: 'white',
              padding: 24,
              borderRadius: 12,
              shadowColor: '#101828',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.1,
              shadowRadius: 4,
              elevation: 2,
            }}
          >
      <>
      <TouchableOpacity style={{ alignSelf: 'flex-end' }} onPress={() => setShowModal(false)}>
                            <ODSIcon size={24} icon="TB-Close" />
                        </TouchableOpacity>
        <ODSText color={colors.text.primary} style={{ alignSelf: 'center' }} type={'h5'}>
        Open Banking
        </ODSText>
        <ODSText
          color={themeObj.neutral[700]}
          marginTop={14}
          style={{ alignSelf: 'center' }}
          type={'sm'}
        >{`To verify your income and as an alternative to uploading bank statements you can give us access to open banking as a secure expedient way of enabling you to provide your up to date financial data instantly in regards to your tenancy application.
What is Open Banking? Open Banking through FCC Paragon will speed up the process of your tenant referencing. It’s safe, secure, and instant and therefore will help to avoid delays.

Sharing a snapshot of your current financial account history is the same as providing bank statements to us to assess, only easier, faster and safer. This will enable us to verify your income and transactions.

You don’t have to manually upload your bank statements/digital copies saving you time and hassle, and at the same time, it can be done anywhere you have your mobile device with you.

Once you have consented the data is instantly available to FCC Paragon to access and assess in relation to your tenancy application.

Can you trust the service? Simply put, yes you can. FCC Paragon is licensed with Consents.Online an Equifax company to carry out such checks and are also registered with the ICO and the FCA as a regulated company. Consents.Online an Equifax company is licenced and authorised by the FCA (Financial Conduct Authority).`}
        </ODSText>
      </>
      
          </View>
        </ODSModal>
      </Wrapper>
    </>
  );
};

export default PreRefrenceNew;
