import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getPlatformType from '../../../helpers/getPlatform';
import { MainSection, Typography } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions } from './../openFlowRedux/tenant.actions';
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import { BoxShadow } from '../../tds/style';
import { COLORS } from '../../../constants/colorPallete';
import moment from 'moment';
import { TabContainer } from '../../idVerification/styles';
import Modal  from "react-native-modal";
import { Image } from 'react-native';
import { formatDate } from '../../../helpers/functionHelpers';
import { getTokens } from '../../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSTabs from '../../../../openbrix-design-system/ui/atoms/ODSTabs';
import ODSdocumentCard from '../../../../openbrix-design-system/ui/molecules/propertyBox/ODSdocumentCard';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';
import { useTheme } from '@emotion/react';

const layoutType = getPlatformType();
const citizenAdviseLink = 'https://www.citizensadvice.org.uk/';
const governmentDepositLink = 'https://www.gov.uk/tenancy-deposit-protection';

const DepositInfo = (props) => {
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const dispatch = useDispatch();
  const {colors}=useTheme();
  const [openFlow, setOpenFlow] = React.useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [moveIn, setMoveIn] = React.useState({});
  const [landlords, setLandlords] = React.useState([]);
  const [showPayByBankModal, setShowPayByBankModal] = React.useState(false);

  const getCurrentTenant = () => {
    return openFlow?.tenants?.find((tenant) => tenant.app_user_id == userId);
  };

  useEffect(() => {
    getOpenflowData();
  }, [props?.route?.params?.id]);

  const getOpenflowData = () => {
    if (props?.route?.params?.id) {
      dispatch(
        tenantActions.getOpenflowData(props?.route?.params?.id, (openflow) => {
          if (openflow) {
            setOpenFlow(openflow);
            setMoveIn(openflow.open_flow_move_in);
            setLandlords(openflow.open_flow_landlords);
          }
        })
      );
    }
  };

  const getAgentName = () => {
    return `${openFlow?.branch?.name}, ${openFlow?.branch?.company?.name}`;
  };

  const getInitialRentalAmount = () => {
    if (openFlow.open_flow_rental_offer && openFlow.open_flow_rental_offer?.initial_rental_amount) {
      return openFlow.open_flow_rental_offer?.initial_rental_amount;
    } else {
      return 0;
    }
  };
  const getTenancyDepositAmount = () => {
    if (
      openFlow.open_flow_rental_offer &&
      openFlow.open_flow_rental_offer?.tenancy_deposit_amount
    ) {
      return openFlow.open_flow_rental_offer?.tenancy_deposit_amount;
    } else {
      return 0;
    }
  };

  const getHoldingDeposit = () => {
    if (
      openFlow.open_flow_holding_deposit &&
      openFlow.open_flow_holding_deposit?.holding_desposit_amount &&
      openFlow.open_flow_holding_deposit?.is_holding_deposit_selected
    ) {
      return openFlow.open_flow_holding_deposit.holding_desposit_amount;
    } else {
      return 0;
    }
  };
  const getTotalAmount = () => {
    const total = getInitialRentalAmount() + getTenancyDepositAmount() - getHoldingDeposit();
    return total.toFixed(2);
  };

  const getMoveInMoneyDueFromYou = () => {
    if (openFlow?.move_in_money_from_lead_tenant) {
      const currentTenant = getCurrentTenant();
      if (currentTenant?.is_lead) {
        return getTotalAmount();
      } else {
        return 0;
      }
    } else {
      return getAmountPerApplicant();
    }
  };
  const getAmountPerApplicant = () => {
    const total = getTotalAmount();
    if (openFlow?.tenants?.length > 0) {
      return (total / openFlow?.tenants?.length).toFixed(2);
    } else {
      return total;
    }
  };

  const renderDetailsCard = () => {
    const currentTenant = getCurrentTenant();
    return (
      <View style={[styles.paddingStyle, { paddingVertical: 20 }]}>
        <ODSText marginBottom={10} type={'h5'}>
          Tenancy Deposit Scheme Information
        </ODSText>
        <ODSText marginBottom={10} type={'sm'}>
          As part of your tenancy, your landlord has requested a deposit to be paid. This is common
          in most tenancies in the UK.
        </ODSText>
        <ODSText marginBottom={10} type={'sm'}>
          There are rules and regulations your landlord has to follow when requesting the deposit.
        </ODSText>
        <ODSText marginBottom={10} type={'sm'}>
          Further information on deposits is available on this{' '}
          <TouchableOpacity
              onPress={() => {
                window.open(governmentDepositLink, '_blank', 'noreferrer');
              }}>      
          <ODSText
            color={themeObj.main.blue['700']}
            type={'sm'}
          >
            government website.
          </ODSText>
          </TouchableOpacity>
        </ODSText>

        <ODSText marginBottom={10} type={'sm'}>
          If you require any legal assistance, please contact your legal advisor or your local{' '}
          <TouchableOpacity
              onPress={() => {
                window.open(citizenAdviseLink, '_blank', 'noreferrer');
              }}>      
          <ODSText
           
            color={themeObj.main.blue['700']}
            type={'sm'}
          >
            citizens advice.
          </ODSText>
          </TouchableOpacity>
        </ODSText>
        <View
          style={{
            borderBottomColor: colors.other['border-neutral'],
            borderBottomWidth: StyleSheet.hairlineWidth,
            marginVertical: 20,
          }}
        />

        <ODSText type={'h5'}>TDS information document</ODSText>
        <ODSText marginTop={20} type={'button'}>
          TDS Provider
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.name
            ? openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.name
            : '-'}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          TDS Website
        </ODSText>
        <TouchableOpacity
              onPress={() => {
                window.open(openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.link, '_blank', 'noreferrer');
              }}>     
        <ODSText    color={themeObj.main.blue['700']} marginTop={6} type={'sm'}>
          {openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.link
            ? openFlow?.open_flow_move_in?.open_flow_tenancy_deposit_scheme?.link
            : '-'}
        </ODSText>
        </TouchableOpacity>
      </View>
    );
  };
  const handlePayByBankClick = () => {
    dispatch(
      tenantActions.acceptMoveinApi(openFlow?.openflow_id, () => {
        getOpenflowData();
      })
    );
    setShowPayByBankModal(false);
  };
  const openCertificate = (item) => {
    window.open(item, '_blank', 'noreferrer');
    if (!moveIn?.inventory_is_seen) {
      dispatch(
        tenantActions.toggleSeen(item, props?.route?.params?.id, () => {
          getOpenflowData();
        })
      );
    }
  };

  const renderPayModal = () => {
    return (
      <Modal
        isVisible={showPayByBankModal}
        transparent={true}
        coverScreen={true}
        hasBackdrop={true}
        backdropOpacity={0}
        style={{
          margin: 0,
          width: '100%',
          flex: 1,
          backgroundColor: '#00000080',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8%',
        }}
      >
        <View style={styles.centeredView}>
          <ODSText  color={colors.text.primary} alignSelf={'center'} type={'h5'}>
            Pay By Bank
          </ODSText>

          <ODSText  color={colors.text.primary} alignSelf={'center'} marginTop={20} type={'sm-bold'}>
            How do I pay using Pay by Bank?
          </ODSText>
          <ODSText  color={colors.text.primary} alignSelf={'center'} marginTop={10} type={'sm'}>
            Please go to your bank and manually make your payment to the following account:
          </ODSText>
          <View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 5,
                marginTop: 24,
              }}
            >
              <ODSText color={colors.text.primary} type={'sm-bold'}>Account Name</ODSText>
              <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
                {moveIn?.OpenbrixBankAccount?.account_name}
              </ODSText>
            </View>

            <View
              style={{
                borderBottomColor: colors.other['border-neutral'],
                borderBottomWidth: StyleSheet.hairlineWidth,
                marginVertical: 10,
              }}
            />

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 5,
              }}
            >
              <ODSText color={colors.text.primary} type={'sm-bold'}>Account Number</ODSText>
              <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
                {moveIn?.OpenbrixBankAccount?.account_id}
              </ODSText>
            </View>

            <View
              style={{
                borderBottomColor: colors.other['border-neutral'],
                borderBottomWidth: StyleSheet.hairlineWidth,
                marginVertical: 10,
              }}
            />

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 5,
              }}
            >
              <ODSText color={colors.text.primary} type={'sm-bold'}>Sort Code</ODSText>
              <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
                {moveIn?.OpenbrixBankAccount?.sort_code}
              </ODSText>
            </View>

            <View
              style={{
                borderBottomColor: colors.other['border-neutral'],
                borderBottomWidth: StyleSheet.hairlineWidth,
                marginVertical: 10,
              }}
            />

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 5,
              }}
            >
              <ODSText color={colors.text.primary} type={'sm-bold'}>Payee Reference</ODSText>
              <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
                {openFlow?.movein_payee_ref}
              </ODSText>
            </View>
          </View>
          <ODSText alignSelf={'center'} marginTop={10} color={colors.text.primary} type={'sm-bold'}>
            Remember to include payment reference.
          </ODSText>
          <View
            style={{
              display: 'flex',
              flexDirection: layoutType=="phone"?"column": 'row',
              justifyContent: 'space-between',
              marginBottom: 5,
              marginTop: 20,
            }}
          >
            <ODSButton
              restStyle={ layoutType=="phone"?{marginBottom:20}: {  width: '45%' }}
              type="primary"
              disabled={false}
              onPress={handlePayByBankClick}
            >
              I’ve Paid
            </ODSButton>
            <ODSButton
                     restStyle={ layoutType=="phone"?{marginBottom:20}: {  width: '45%' }}
              type="secondary"
              disabled={false}
              onPress={() => {
                setShowPayByBankModal(false);
              }}
            >
              I’ll Pay this Later
            </ODSButton>
          </View>
        </View>
      </Modal>
    );
  };

  const renderStatus = () => {
    const currentTenant = getCurrentTenant();

    if (currentTenant) {
      if (currentTenant.movein_money_status == 0) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={true}
              onPress={() => {}}
            >
              NOT REQUESTED
            </ODSButton>
          </View>
        );
      } else if (currentTenant?.movein_money_status == 1) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <TouchableOpacity
              activeOpacity={0.9}
              style={{
                borderRadius: 6,
                paddingVertical: 10,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: COLORS.YELLOW,

                width: '70%',
                marginBottom: 16,
                alignSelf: 'center',
              }}
            >
              <ODSText type={'sm'}>Deposit requested</ODSText>
            </TouchableOpacity>
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => {
                setShowPayByBankModal(true);
              }}
            >
              PAY BY BANK
            </ODSButton>
          </View>
        );
      } else if (currentTenant.movein_money_status == 3) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => {}}
            >
              Paid
            </ODSButton>
          </View>
        );
      } else if (currentTenant.movein_money_status == 2) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <ODSButton
              restStyle={{ width: '100%', backgroundColor: '#FFC059' }}
              type="primary"
              disabled={false}
              onPress={() => {}}
            >
              WAITING FOR CONFIRMATION
            </ODSButton>
          </View>
        );
      }
    }
  };
  const TabView = () => {
    return (
      <ODSTabs
        items={[openFlow?.is_renewed?'Renewal Payments':'Move In Monies',openFlow?.is_renewed?'Property Visit' :'Inventory & Check in']}
        selectedColor={themeObj.main.blue[700]}
        color={themeObj.main.blue[500]}
        activeTab={selectedIndex}
        onTabChange={(val) => setSelectedIndex(val)}
        restStyle={{
          borderRadius: 0,
          borderTopEndRadius: 8,
          borderTopStartRadius: 8,
          minHeight: 68,
          marginBottom: 20,
        }}
      />
    );
  };
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState('');

  const getInventoryCheckStatusAndColorCode = () => {
    const status = moveIn?.inventory_check_status;
    switch (status) {
      case 0:
        return {
          text: 'Not Started',
          color: COLORS.BLACK,
        };
      case 1:
        return {
          text: 'Pending',
          color: COLORS.YELLOW,
        };
      case 2:
        return {
          text: 'Completed',
          color: COLORS.LGREEN,
        };
      default:
        return {
          text: 'NA',
          color: COLORS.BLACK,
        };
        break;
    }
  };
  const getCheckInStatusAndColorCode = () => {
    const status = moveIn?.checkin_status;
    switch (status) {
      case 0:
        return {
          text: 'Not Started',
          color: COLORS.BLACK,
        };
      case 1:
        return {
          text: 'Pending',
          color: COLORS.YELLOW,
        };
      case 2:
        return {
          text: 'Completed',
          color: COLORS.LGREEN,
        };
      default:
        return {
          text: 'NA',
          color: COLORS.BLACK,
        };
        break;
    }
  };
  const renderInventoryDocument = (docs) => {
    let array = docs.split(',');
    return array.map((item, index) => (
      <ODSdocumentCard
        title={`Inventory Document ${index + 1}`}
        description={moment(moveIn?.inventory_doc_upload_date).format('DD/MM/YYYY')}
        onPress={() => openCertificate(item)}
        hideRightIcon
        style={{marginBottom:10}}
      />
    ));
  };
  const renderInventory = () => {
    return (
      <View style={[styles.paddingStyle, { paddingBottom: 20 }]}>
        <ODSText type={'h5'}>Inventory Check</ODSText>

        <ODSText marginTop={20} type={'button'}>
          Date
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {moveIn?.inventory_check_date
            ? moment(moveIn?.inventory_check_date).format('Do MMM YYYY')
            : 'N/A'}
        </ODSText>

        <ODSText marginTop={20} type={'button'}>
          Status
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {getInventoryCheckStatusAndColorCode().text}
        </ODSText>
        <View
          style={{
            borderBottomColor: colors.other['border-neutral'],
            borderBottomWidth: StyleSheet.hairlineWidth,
            marginVertical: 20,
          }}
        />
        <ODSText type={'h5'}>Check In</ODSText>
        <ODSText marginTop={20} type={'button'}>
          Date
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {moveIn?.checkin_date ? moment(moveIn?.checkin_date).format('Do MMM YYYY') : 'N/A'}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Status
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {getCheckInStatusAndColorCode().text}
        </ODSText>

        <View
          style={{
            borderBottomColor: colors.other['border-neutral'],
            borderBottomWidth: StyleSheet.hairlineWidth,
            marginVertical: 20,
          }}
        />
        {moveIn?.inventory_doc &&
          moveIn?.inventory_doc.length &&
          renderInventoryDocument(moveIn?.inventory_doc)}
      </View>
    );
  };
  const getLeadLandlord = () => {
    const leadLandlord = landlords.find((landlord) => landlord.is_lead);
    if (leadLandlord) {
      return leadLandlord;
    } else if (landlords.length > 0) {
      return landlords[0];
    } else {
      return {};
    }
  };
  console.log('getLeadLandlord', getLeadLandlord());
  const getSubTotal = () => {
    return getTenancyDepositAmount() + getInitialRentalAmount();
  };

  const getLandLoadFullName = () => {
    const leadLandlord = getLeadLandlord();
    if (leadLandlord?.first_name) {
      return `${leadLandlord?.title} ${leadLandlord?.first_name} ${leadLandlord?.last_name}`;
    } else {
      return '';
    }
  };

  const renderMoveInform = () => {
    const currentTenant = getCurrentTenant();
    return (
      <View style={styles.paddingStyle}>
        <ODSText type={'button'}>Date Sent On</ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {moment(currentTenant?.movein_money_sent_at).format('Do MMM YYYY')}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Agent
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {getAgentName()}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Amount
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          £ {getTotalAmount()}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Amount per applicant
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          £ {getAmountPerApplicant()}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Collected from
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {openFlow?.move_in_money_from_lead_tenant ? 'Lead Tenant' : 'All Tenants'}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Payment due from you
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          £ {getMoveInMoneyDueFromYou()}
        </ODSText>
        <View
          style={{
            borderBottomColor: colors.other['border-neutral'],
            borderBottomWidth: StyleSheet.hairlineWidth,
            marginVertical: 20,
          }}
        />
        <ODSText type={'h5'}>Move in Monies Breakdown</ODSText>

        <ODSText marginTop={20} type={'button'}>
          Tenancy Deposit
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          £ {getTenancyDepositAmount().toFixed(2)}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Initial rental payment
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          £ {getInitialRentalAmount().toFixed(2)}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Subtotal
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          £ {getSubTotal().toFixed(2)}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
        HOLDING DEPOSIT PAID
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          - £ {getHoldingDeposit().toFixed(2)}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Total
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          £ {getTotalAmount()}
        </ODSText>
        <View
          style={{
            borderBottomColor: colors.other['border-neutral'],
            borderBottomWidth: StyleSheet.hairlineWidth,
            marginVertical: 20,
          }}
        />

        <ODSText type={'sm'}>
          In accordance of Section 47 and 48 of the Landlord and Tenant ACT 1987The landlord names
          and address, in England and Wales, at which Notices maybe served on the landlord by the
          tenant:
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Landlord name
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {getLandLoadFullName()}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Landlord address
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {getLeadLandlord()?.correspondece_address}
        </ODSText>
        <View
          style={{
            borderBottomColor: colors.other['border-neutral'],
            borderBottomWidth: StyleSheet.hairlineWidth,
            marginVertical: 20,
          }}
        />
        <ODSText type={'h5'}>Agent account details</ODSText>

        <ODSText marginTop={20} type={'button'}>
          Account name
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {moveIn?.OpenbrixBankAccount?.account_name}
        </ODSText>

        <ODSText marginTop={20} type={'button'}>
          Account number
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {moveIn?.OpenbrixBankAccount?.account_id}
        </ODSText>

        <ODSText marginTop={20} type={'button'}>
          Sort code
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {moveIn?.OpenbrixBankAccount?.sort_code}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          Swift code
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {moveIn?.OpenbrixBankAccount?.swift_code}
        </ODSText>
        <ODSText marginTop={20} type={'button'}>
          IBAN
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {moveIn?.OpenbrixBankAccount?.iban}
        </ODSText>

        <ODSText marginTop={20} type={'button'}>
          Payee reference
        </ODSText>
        <ODSText marginTop={6} type={'sm'}>
          {openFlow?.movein_payee_ref}
        </ODSText>
        <View style={{ marginTop: 30, marginBottom: 20 }}>{renderStatus()}</View>
      </View>
    );
  };
  const renderTermsCard = () => {
    return (
      <>
        <TabView />
        {selectedIndex == 0 ? renderMoveInform() : null}
        {selectedIndex == 1 ?openFlow?.is_renewed? <ODSText textAlign='center' padding={20} color={colors.text.primary} type='md-bold'>To be confirmed by letting Agent</ODSText> :renderInventory() : null}
      </>
    );
  };
  const themeObj = getTokens('light').colors;
  const renewal_payment_desc=`Please pay the renewal payments, then click on the “I've paid” button.
A breakdown of your "Renewal Payments", including the prescribed information and all  other relevant information regarding the protection of your deposit.`;
  return (
    <BackGroundGenerator
      props={props}
      isTLYF
      isOpenflowContainer
      openFlowData={{
        title: openFlow?.is_renewed?'Renewal Payments':'Move in',
        desc: openFlow?.is_renewed? renewal_payment_desc :'A breakdown of your "Moving in Monies", including the prescribed information and all  other relevant information regarding the protection of your deposit.',
        buttonText: openFlow?.move_in_status,
        message: openFlow?.move_in_message,
        listIcon: 'movein',
      }}
    >
      <MainSection
        tion
        style={{
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
          <BoxShadow style={{ padding: 0 }}>{renderDetailsCard()}</BoxShadow>
        </View>
        <View
          style={layoutType == 'phone' ? { flex: 1, marginTop: 26, width: '100%' } : { flex: 0.48 }}
        >
          <BoxShadow style={{ padding: 0 }}>{renderTermsCard()}</BoxShadow>
        </View>
      </MainSection>

      {renderPayModal()}
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({
  paddingStyle: {
    paddingHorizontal: layoutType == 'phone' ? 12 : 20,
  },
  rowFlexView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
    backgroundColor: 'white',
    borderRadius: 20,
  },
  whiteColor12Regular: { color: '#FFFFFF', fontSize: 12 },
  rowItem: { flexDirection: 'column', flex: 5, alignItems: 'flex-start' },
});
export default DepositInfo;
