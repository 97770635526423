import React, { useEffect } from 'react';
import { ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import getPlatformType from '../../../helpers/getPlatform';
import { MainSection, Typography } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { tenantActions } from './../openFlowRedux/tenant.actions';
import Modal from "react-native-modal";
import BackGroundGenerator from '../../LandingPages/common/BackGroundGenerator';
import { BoxShadow } from '../../tds/style';
import { COLORS } from '../../../constants/colorPallete';
import moment from 'moment';
import { getTokens } from '../../../../openbrix-design-system/design-tokens/color-tokens';
import { ODSText } from '../../../../openbrix-design-system/ui/atoms/ODSText';
import ODSButton from '../../../../openbrix-design-system/ui/atoms/ODSButton';
import Portal from '../../../../openbrix-design-system/ui/atoms/ODSModal/potral';
import { useTheme } from '@emotion/react';

const layoutType = getPlatformType();

const HoldingDeposit = (props) => {
  const [showPayByBankModal, setShowPayByBankModal] = React.useState(false);
  const dispatch = useDispatch();
  const {colors}=useTheme();
  const userId = useSelector((state) => state.authReducer?.profile?.data?.appUser?.app_user_id);
  const [openFlow, setOpenFlowData] = React.useState({});
  const [holdingDeposit, setHoldingDeposit] = React.useState({});
  useEffect(() => {
    getOpenflowData();
  }, [props?.route?.params?.id]);
  const getOpenflowData = () => {
    if (props?.route?.params?.id) {
      dispatch(
        tenantActions.getOpenflowData(props?.route?.params?.id, (openflowData) => {
          setOpenFlowData(openflowData);
          setHoldingDeposit(openflowData.open_flow_holding_deposit);
        })
      );
    }
  };
  const getCurrentTenant = () => {
    return openFlow?.tenants?.find((tenant: any) => tenant?.app_user_id == userId);
  };
  const getAgentName = () => {
    return `${openFlow?.branch?.name}, ${openFlow?.branch?.company?.name}`;
  };

  const getHoldingDepositPerApplicant = () => {
    if (holdingDeposit?.holding_desposit_amount) {
      return (holdingDeposit?.holding_desposit_amount / openFlow?.tenants?.length).toFixed(2);
    } else {
      return 0;
    }
  };

  const getHoldingDepositDueFromYou = () => {
    if (openFlow?.holding_deposit_collection_from_lead_tenant) {
      const currentTenant = getCurrentTenant();
      if (currentTenant?.is_lead) {
        return holdingDeposit?.holding_desposit_amount;
      } else {
        return 0;
      }
    } else {
      return getHoldingDepositPerApplicant();
    }
  };

  const handlePayByBankClick = () => {
    dispatch(
      tenantActions.confirmDeposit(openFlow?.openflow_id, () => {
        getOpenflowData();
      })
    );

    setShowPayByBankModal(false);
  };

  const renderDetailsCard = () => {
    const currentTenant = getCurrentTenant();
    return (
      <View style={styles.paddingStyle}>
        <View style={[styles.rowFlexView, { borderWidth: 0, marginVertical: 0, marginTop: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Date Sent
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {moment(currentTenant?.deposit_sent_at).format('Do MMM YYYY')}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Agent
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {getAgentName()}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Total Amount
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              £ {holdingDeposit?.holding_desposit_amount}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Deposit per applicant
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              £ {getHoldingDepositPerApplicant()}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Deposit collected from
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {openFlow?.holding_deposit_collection_from_lead_tenant
                ? 'Lead Tenant'
                : 'All Tenants'}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView, { borderWidth: 0 }]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Payment due from you
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              £ {getHoldingDepositDueFromYou()}
            </ODSText>
          </View>
        </View>
      </View>
    );
  };
  const renderStatus = () => {
    const currentTenant = getCurrentTenant();
    if (currentTenant) {
      if (currentTenant.deposit_payment_status == 0) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={true}
              onPress={() => { }}
            >
              NOT REQUESTED
            </ODSButton>
          </View>
        );
      } else if (currentTenant.deposit_payment_status == 1) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <TouchableOpacity
              activeOpacity={0.9}
              style={{
                borderRadius: 6,
                paddingVertical: 10,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: COLORS.YELLOW,

                width: '70%',
                marginBottom: 16,
                alignSelf: 'center',
              }}
            >
              <ODSText type={'sm'}>Deposit Requested</ODSText>
            </TouchableOpacity>
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => {
                setShowPayByBankModal(true);
              }}
            >
              PAY BY BANK
            </ODSButton>
          </View>
        );
      } else if (currentTenant.deposit_payment_status == 2) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <ODSButton
              restStyle={{ width: '100%', backgroundColor: '#FFC059' }}
              type="primary"
              disabled={false}
              onPress={() => { }}
            >
              WAITING FOR CONFIRMATION
            </ODSButton>
          </View>
        );
      } else if (currentTenant.deposit_payment_status == 3) {
        return (
          <View
            style={{
              flexDirection: 'column',
              width: layoutType == 'web' ? 400 : '100%',
              alignSelf: 'center',
            }}
          >
            <ODSButton
              restStyle={{ width: '100%' }}
              type="primary"
              disabled={false}
              onPress={() => { }}
            >
             HOLDING DEPOSIT PAID
            </ODSButton>
          </View>
        );
      }
    }
  };
  const renderTermsCard = () => {
    return (
      <View style={styles.paddingStyle}>
        <ODSText color={colors.text.primary} type={'h5'}>
          Holding Deposit Terms
        </ODSText>

        <View
          style={[
            {
              borderWidth: 1,
              flexDirection: 'column',
              borderRadius: 10,
              borderColor: COLORS.LABELGREY,
              marginTop: 20,
            },
          ]}
        >
          <ScrollView style={{ height: 200, margin: 16 }}>
            <ODSText color={colors.text.primary} type={'sm'}>
              A holding deposit or “holding fee” is a financial sum that tenant(s) pay as
              confirmation of their intent to proceed with an application to rent a property. The
              deposit secures the property for tenant(s). It is payable to the landlord or their
              letting agent and can legally be no more than one week’s rent. Please note the holding
              deposit is usually deducted from the "move in monies”.
            </ODSText>
            <ODSText marginTop={10} marginBottom={10} color={colors.text.primary} type={'button'}>
              Your deposit will be refunded within 7 days if:
            </ODSText>

            {[
              { key: 'The landlord alters the terms and you are no longer in agreement or' },
              { key: 'Your landlord decides not to proceed or' },
              {
                key: 'The tenancy has not commenced by the agreed start date or within 15 days of the date of payment of the holding deposit (whichever is the later) and no extension has been agreed, For the avoidance of doubt the start date is noted in the Rental Offer.',
              },
            ].map((item, index) => (
              <ODSText marginBottom={8} color={colors.text.primary} type={'sm'}>
                {index + 1}. {item.key}
              </ODSText>
            ))}
            <ODSText marginTop={10} marginBottom={10} color={colors.text.primary} type={'button'}>
              Your deposit will not be refunded if:
            </ODSText>

            {[
              { key: 'You decide not to proceed with the tenancy or' },
              {
                key: 'You do not take all reasonable steps to proceed with and enter into the tenancy, whilst the',
              },
              {
                key: 'You do not take all reasonable steps to proceed with and enter into the tenancy, whilst the landlord has taken all reasonable steps to proceed with and enter into a tenancy with you or',
              },
              {
                key: 'You provide false or misleading information in respect of your ability to meet referencing criteria (detailed below) or',
              },
              { key: 'You fail your Right to Rent checks' },
            ].map((item, index) => (
              <ODSText marginBottom={8} color={colors.text.primary} type={'sm'}>
                {index + 1}. {item.key}
              </ODSText>
            ))}
            <ODSText marginBottom={10} marginTop={8} color={colors.text.primary} type={'button'}>
              In order to pass the referencing process you will be required to meet the following
              criteria:
            </ODSText>

            {[
              {
                key: 'Affordability – your share of the rent should not exceed 40% of your annual gross salary',
              },
              {
                key: 'Employment – your employer will be asked to confirm your terms of employment and salary. If you are self-employed you will need to provide evidence of your income such as a tax return or accountants letter',
              },
              {
                key: 'Previous Landlord – if applicable your previous landlord will need to confirm your suitability as a tenant and that there were no breaches of the tenancy such as damages or rent arrears.',
              },
            ].map((item, index) => (
              <ODSText marginBottom={8} color={colors.text.primary} type={'sm'}>
                {index + 1}. {item.key}
              </ODSText>
            ))}
          </ScrollView>
        </View>

        <ODSText marginTop={20} color={colors.text.primary} type={'h5'}>
          Additional Deposit Terms
        </ODSText>

        <View
          style={[
            {
              borderWidth: 1,
              flexDirection: 'column',
              borderRadius: 10,
              borderColor: COLORS.LABELGREY,
              marginTop: 20,
            },
          ]}
        >
          <ScrollView style={{ minHeight: 50, margin: 16 }}>
            <ODSText marginBottom={8} color={colors.text.primary} type={'sm'}>
              {holdingDeposit?.holding_deposit_text}
            </ODSText>
          </ScrollView>
        </View>

        <ODSText marginTop={20} marginBottom={10} color={colors.text.primary} type={'h5'}>
          Account Details
        </ODSText>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Account Name
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {holdingDeposit?.OpenbrixBankAccount?.account_name}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Account Number
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {holdingDeposit?.OpenbrixBankAccount?.account_id}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Sort Code
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {holdingDeposit?.OpenbrixBankAccount?.sort_code}
            </ODSText>
          </View>
        </View>
        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Swift Code
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {holdingDeposit?.OpenbrixBankAccount?.swift_code}
            </ODSText>
          </View>
        </View>

        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              IBAN
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {holdingDeposit?.OpenbrixBankAccount?.iban}
            </ODSText>
          </View>
        </View>

        <View style={[styles.rowFlexView]}>
          <View style={styles.rowItem}>
            <ODSText color={colors.text.primary} type={'button'}>
              Payee Reference
            </ODSText>
            <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
              {openFlow?.payee_ref}
            </ODSText>
          </View>
        </View>

        <View style={styles.paddingStyle}>{renderStatus()}</View>
      </View>
    );
  };
  const renderPayModal = () => {
    return (
      <Portal>
        <Modal
          isVisible={showPayByBankModal}
          transparent={true}
          coverScreen={true}
          hasBackdrop={true}
          backdropOpacity={0}
          style={{
            margin: 0,
            width: '100%',
            backgroundColor: '#00000080',
            justifyContent: 'center',
            alignItems: 'center',
            padding:'7%'
          }}
        >
          <View style={styles.centeredView}>
            <ODSText color={colors.text.primary} alignSelf={'center'} type={'h5'}>
              Pay By Bank
            </ODSText>

            <ODSText color={colors.text.primary} alignSelf={'center'} marginTop={20} type={'sm-bold'}>
              How do I pay using Pay by Bank?
            </ODSText>
            <ODSText color={colors.text.primary} alignSelf={'center'} marginTop={10} type={'sm'}>
              Please go to your bank and manually make your payment to the following account:
            </ODSText>

            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                  marginTop: 24
                }}
              >
                <ODSText color={colors.text.primary} type={'sm-bold'}>Account Name</ODSText>

                <ODSText  marginTop={8} color={colors.text.primary} type={'sm'}>
                  {holdingDeposit?.OpenbrixBankAccount?.account_name}
                </ODSText>
              </View>

              <View style={{ borderBottomColor:colors.other['border-neutral'], borderBottomWidth: 1, marginVertical: 10 }} />

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                }}
              >
                <ODSText color={colors.text.primary} type={'sm-bold'}>Account Number</ODSText>

                <ODSText color={colors.text.primary} marginTop={8} type={'sm'}>
                  {holdingDeposit?.OpenbrixBankAccount?.account_id}
                </ODSText>
              </View>

              <View style={{ borderBottomColor:colors.other['border-neutral'], borderBottomWidth: 1, marginVertical: 10 }} />

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                }}
              >
                <ODSText type={'sm-bold'}>Sort Code</ODSText>

                <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
                  {holdingDeposit?.OpenbrixBankAccount?.sort_code}
                </ODSText>
              </View>

              <View style={{ borderBottomColor:colors.other['border-neutral'], borderBottomWidth: 1, marginVertical: 10 }} />

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 5,
                }}
              >
                <ODSText type={'sm-bold'}>Payee Reference</ODSText>

                <ODSText marginTop={8} color={colors.text.primary} type={'sm'}>
                  {openFlow?.payee_ref}
                </ODSText>
              </View>
            </View>
            <View style={{ borderBottomColor:colors.other['border-neutral'], borderBottomWidth: 1, marginVertical: 10 }} />
            <ODSText color={colors.text.primary} alignSelf={'center'} marginTop={10} type={'xs-bold'}>Remember to include payment reference.</ODSText>

            <View
              style={{
                display: 'flex',
                flexDirection: layoutType == "phone" ? "column" : 'row',
                justifyContent: 'space-between',
                marginBottom: 5,
                marginTop: 20
              }}
            >
              <ODSButton
                restStyle={layoutType == "phone" ? { marginBottom: 20 } : { width: '45%' }}
                type="primary"
                disabled={false}
                onPress={handlePayByBankClick}
              >
                I’ve Paid
              </ODSButton>
              <ODSButton
                restStyle={layoutType == "phone" ? { marginBottom: 20 } : { width: '45%' }}
                type="secondary"
                disabled={false}
                onPress={() => {
                  setShowPayByBankModal(false);
                }}
              >
                I’ll Pay this Later
              </ODSButton>
            </View>
          </View>
        </Modal>
      </Portal>
    );
  };
  const themeObj = getTokens('light').colors;
  return (
    <BackGroundGenerator
      props={props}
      isTLYF
      isOpenflowContainer
      openFlowData={{
        title: 'Holding Deposit',
        desc: 'Paying a Holding Deposit indicates your intention to accept the terms of the rental, subject to contract. After payment, confirm below.',
        buttonText: openFlow?.holding_deposit_status,
        message: openFlow?.holding_deposit_message,
        listIcon: 'pound',
      }}
    >
      <MainSection
        tion
        style={{
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          justifyContent: 'space-between',
        }}
      >
        <View style={layoutType == 'phone' ? { width: '100%' } : { flex: 0.48 }}>
          <BoxShadow style={{ padding: 0 }}>{renderDetailsCard()}</BoxShadow>
        </View>
        <View
          style={layoutType == 'phone' ? { flex: 1, marginTop: 26, width: '100%' } : { flex: 0.48 }}
        >
          <BoxShadow style={{ padding: 0 }}>{renderTermsCard()}</BoxShadow>
        </View>
        {renderPayModal()}
      </MainSection>
    </BackGroundGenerator>
  );
};
const styles = StyleSheet.create({
  paddingStyle: {
    padding: 12,
  },
  rowFlexView: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 10,
  },
  centeredView: {
    flex: 1,
    padding: 20,
    marginTop: 22,
    backgroundColor: 'white',
    borderRadius: 20,
  },
  whiteColor12Regular: { color: '#FFFFFF', fontSize: 12 },
  rowItem: { flexDirection: 'column', flex: 5, alignItems: 'flex-start' },
});
export default HoldingDeposit;
