import {FontAwesome} from '@expo/vector-icons';
import {LinearGradient} from 'expo-linear-gradient';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {ActivityIndicator, FlatList, Image, ImageBackground, StyleSheet, Text, TextInput, TouchableOpacity, View,} from 'react-native';

import 'react-horizontal-scrolling-menu/dist/styles.css';

import {Typography} from '../openFlow/style';
import {COLORS} from '../../constants/colorPallete';
import {openUrlHelpers} from '../../helpers/openDocHelpers';
import {tenantActions} from '../openFlow/openFlowRedux/tenant.actions';
import "./homebox.css";
import generatePartnerUrl from '../../../openbrix-design-system/utils/functions';
import { HOME_BASE_URL } from '../../helpers/connectionHelpers';
const HomeBoxCard = React.memo(({ profile,postCodeFromTeancy }: any) => {
  const dispatch = useDispatch();
  const listRef = React.useRef();
  const [page, setPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [products, setProducts] = useState([]);
  const [postcode, setPostCode] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleItem, setVisibleItem] = useState([]);
  const [name, setName] = useState({ value: '', error: '' });
  const postCodeData = useSelector((state: any) => state?.tenant?.postCodeData);
  const iFrameParams = useSelector((state:any) => state.authReducer?.iFrameParams);


  useEffect(() => {
    if (profile?.appUser?.new_postcode_search) {
      dispatch(
        tenantActions.setPostCodeAction({ value: profile?.appUser?.new_postcode_search, error: '', isSubmit: true })
      );
    }else if(postCodeFromTeancy){
        dispatch(
            tenantActions.setPostCodeAction({ value: postCodeFromTeancy, error: '', isSubmit: true })
          );  
    }
  }, [profile]);
  useEffect(() => {
    if (postCodeData?.isSubmit) {
      getRecommendations(postCodeData.value);
    }
  }, [postCodeData?.isSubmit]);

  useEffect(() => {
    if (postCodeData?.isSubmit) {
      getRecommendations(postCodeData.value);
    }
  }, [postCodeData?.isSubmit]);

  const refreshData = () => {
    if (hasMore) {
      getRecommendations(postcode);
    }
  };

  const getRecommendations = (postcode1: string) => {
    setLoader(true);
    dispatch(
      tenantActions.getBroadBandRecommendations({ postcode: postcode1, page }, (response) => {
        setLoader(false);
        setIsLoading(false);
        setPostCode(postcode1);
        setTotalCount(response?.totalCount);
        if (response?.products?.length < 10) {
          setHasMore(false);
        }
        setPage(page + 1);
        if (page == 0) {
          const products1 = response.products.filter((product2) => {
            if (
              product2.externalProductId ==
              response.recommendations.cheapestProduct.externalProductId
            ) {
              return false;
            } else if (
              product2.externalProductId ==
              response.recommendations.fastestProduct.externalProductId
            ) {
              return false;
            } else {
              return true;
            }
          });
          setProducts([
            response.recommendations.cheapestProduct,
            response.recommendations.fastestProduct,
            ...products1,
          ]);
        } else {
            if(response?.products){
                setProducts([...products, ...response.products]);
            }
        }
      })
    );
  };

  const roundedValue = (value: number) => {
    return Math.round(value);
  };

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      onPress={() => {
        openUrlHelpers(item.outlink);
      }}
    >
      <View
        style={{
          marginRight: 20,
          backgroundColor: 'white',
          borderWidth: 1,
          borderColor: COLORS.GREEN,
          width: 230,
          borderRadius: 10,
          paddingHorizontal: 10,
          paddingVertical: 10,
        }}
      >
        <Typography color={COLORS.LGREEN} align={'left'} size={12}  numberOfLines={1} style={{ maxWidth: 170, fontWeight: 'bold', color: COLORS.LGREEN }}>
          {item.externalShortName}
        </Typography>
        <Typography
        align={'left'}
          numberOfLines={1}
          color={'#000000'}
          size={12}
          style={{ maxWidth: 170, fontWeight: '400', color: '#000000', fontSize: 12 }}
        >
          {item.productName}
        </Typography>

        <View style={{ flex: 1, marginTop: 10.0, flexDirection: 'row' }}>
          <View>
            <Typography
            align={'left'}
                color={'#000000'}
                size={12}
              numberOfLines={2}
              style={{ maxWidth: 170, marginRight: 10.0, color: '#000000', fontSize: 12 }}
            >
              <Typography size={12} color={'#000000'}> £{roundedValue(item.monthlyCost)}</Typography>/month
            </Typography>

            <Typography align={'left'} color={'#000000'} size={12} numberOfLines={2} style={{ maxWidth: 170, color: '#000000', fontSize: 12 }}>
              <Typography size={12} color={'#000000'} style={{ fontWeight: 'bold' }}>
                {' '}
                £{roundedValue(item.effectiveFirstYearCost)}
              </Typography>
              /year
            </Typography>
            <Typography
            color={'#000000'}
            size={12}
            align={'left'}
              numberOfLines={2}
              style={{ maxWidth: 170, marginRight: 10.0, color: '#000000', fontSize: 12 }}
            >
              <Typography align={'left'} size={12} color={'#000000'} style={{ fontWeight: 'bold' }}>
                {' '}
                {roundedValue(item.downloadSpeed / 1000)}MB/s
              </Typography>{' '}
              Avg Speed
            </Typography>

            <Typography align={'left'} color={'#000000'} size={12} numberOfLines={2} style={{ maxWidth: 170, color: '#000000', fontSize: 12 }}>
              <Typography size={12} color={'#000000'} style={{ fontWeight: 'bold' }}> {item.contractLength} </Typography> Month Contract
            </Typography>
          </View>
        </View>
        
       <View style={{flexDirection:"row",justifyContent:'space-between',alignItems:'center'}}>
        
       <Image
          source={{ uri: item.supplierImage }}
          style={{
            width: 60.0,
            height: 60.0,
          }}
          resizeMode="contain"
        ></Image>
        <TouchableOpacity
          activeOpacity={0.9}
          onPress={() => {
            openUrlHelpers(item.outlink);
          }}
          style={{
            backgroundColor: COLORS.LGREEN,
            borderRadius: 10.0 * 2.0,
            paddingVertical: 10.0 - 5.0,
            paddingHorizontal: 10.0,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography size={12} style={{ color: 'white' }}>Switch Now</Typography>
        </TouchableOpacity>
        
        </View>
      </View>
    </TouchableOpacity>
  );

  const handleLoadMore = () => {
    if (!isLoading && hasMore) {
      setIsLoading(true);
      refreshData();
    }
  };

  const renderFooter = () => {
    return isLoading ? (
      <View
        style={{ paddingVertical: 20, justifyContent: 'center', alignItems: 'center', flex: 1 }}
      >
        <ActivityIndicator size="small" color="gray" />
      </View>
    ) : null;
  };

  const onViewCallBack = React.useCallback((viewableItems: any) => {
    setVisibleItem(viewableItems);
  }, []);

  const renderResultPage = () => {
    return (
      <>
        {/* <View style={{ marginTop: 0, flexDirection: 'row', marginBottom: 15 }}>
          <Text>Found</Text>
          <Text style={{ marginLeft: 2, fontWeight: 'bold' }}>{totalCount}</Text>
          <Text style={{ marginLeft: 2 }}>Best Deals</Text>
        </View> */}

        <View style={{ flexDirection: 'row',alignItems:"center",marginTop:10 }}>
          {visibleItem?.viewableItems?.length &&
          visibleItem?.viewableItems[0].index === 0 ? null : (
            <TouchableOpacity
            style={{height:30,width:30,backgroundColor:'white',justifyContent:'center',alignItems:'center',borderRadius:20}}
              onPress={() => {
                const index = products.findIndex((element) => {
                  return (
                    element.externalProductId ==
                    visibleItem?.viewableItems[visibleItem?.viewableItems.length - 1].item
                      .externalProductId
                  );
                });
                if (visibleItem?.viewableItems.length == 1 && index - 1 >= 0) {
                  listRef.current.scrollToIndex({ index: index - 1, animated: true });
                } else if (index == 1 || index == 2) {
                  listRef.current.scrollToIndex({ index: 0, animated: true });
                } else if (index - 5 >= 0) {
                  listRef.current.scrollToIndex({ index: index - 5, animated: true });
                }
              }}
            >
                <FontAwesome name="arrow-left" size={12} color={COLORS.LGREEN}/>
       
            </TouchableOpacity>
          )}
          <FlatList
            data={products}
            ref={listRef}
            horizontal={true}
            renderItem={renderItem}
            onEndReachedThreshold={0.5}
            onEndReached={handleLoadMore}
            ListFooterComponent={renderFooter}
            showsHorizontalScrollIndicator={false}
            onViewableItemsChanged={onViewCallBack}
            keyExtractor={(item) => `${item.externalProductId}`}
            contentContainerStyle={{width:(344-30)}}
          />
         
            <TouchableOpacity
             style={{height:30,width:30,backgroundColor:'white',justifyContent:'center',alignItems:'center',borderRadius:20}}
              onPress={() => {
                const index = products.findIndex((element) => {
                  return (
                    element.externalProductId ==
                    visibleItem?.viewableItems[visibleItem?.viewableItems.length - 1].item
                      .externalProductId
                  );
                });
                if (visibleItem?.viewableItems.length == 1) {
                  listRef.current.scrollToIndex({ index: index + 1, animated: true });
                } else {
                  listRef.current.scrollToIndex({ index, animated: true });
                }
              }}
            >
              <FontAwesome name="arrow-right" size={12}color={COLORS.LGREEN} />
            </TouchableOpacity>
          
        </View>
      </>
    );
  };

  const renderInitialPage = () => {
    return (
        <View style={{ flex: 1 }}>
            <Typography color={'black'} fontWeight={'400'} size={14} style={{ textAlign:'left', marginTop: 20 }}>
                Enter your postcode for an instant quote!
            </Typography>
            <View style={{ marginTop: 10 }}>
                {nameTextField()}
                <TouchableOpacity
                    onPress={() => {
                        if(validatePostcode(postCodeData?.value)){
                          if (postCodeData?.value) {
                            dispatch(
                              tenantActions.setPostCodeAction({ value: postCodeData?.value, error: '', isSubmit: true }),
                            );
                            setName({value:'',error:''});
                          }
                            getRecommendations(postCodeData.value);
                        }
                        else{
                          setName({value:'',error:'Invalid Postcode'});
                        }

                     
                    }}
                    disabled={loader}
                    style={{
                        width: 100,
                        height: 45,
                        marginTop: 30,
                        borderRadius: 1000,
                        backgroundColor: COLORS.LGREEN,
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}>
                    {loader ? <ActivityIndicator color={'#ffffff'} /> :  <Typography size={14}>GO</Typography>}

                </TouchableOpacity>
            </View>
        </View>
    )
  };
  function validatePostcode(postcode) {
    // Define the regular expression for a valid England postcode
    const postcodeRegex = /^[A-Z]{1,2}\d{1,2}[A-Z]?\s?\d[A-Z]{2}$/i;
  
    // Remove any spaces and convert the input to uppercase
    const cleanedPostcode = postcode.replace(/\s/g, '').toUpperCase();
  
    // Check if the postcode matches the regular expression
    if (postcodeRegex.test(cleanedPostcode)) {
      return true; // Valid postcode
    } else {
      return false; // Invalid postcode
    }
  }
  function nameTextField() {
    return (
      <View style={{}}>
        <View style={{ marginTop: 10 }}>
          <TextInput
            style={{
              height: 40,
              borderColor: 'lightgrey',
              borderWidth: 1,
              borderRadius: 10,
              padding: 8,
            }}
            value={postCodeData?.value}
            onChangeText={(text) => {
              dispatch(
                tenantActions.setPostCodeAction({ value: text, error: '', isSubmit: false })
              );
            }}
            placeholder="Enter postcode"
            cursorColor={COLORS.LGREEN}
          ></TextInput>
        </View>
        {name.error ? <Text style={{marginTop:5,color:'red'}}>{name.error}</Text> : null}
      </View>
    );
  }

  return (
    <View style={{ flex:1 }}>
      {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between',height:'20%',padding:15 }}>
        <Typography align={'left'} fontWeight={'bold'} color={COLORS.LGREEN} fontSize={18}>
          Broadband
        </Typography>
        {postcode && (
          <View style={{ marginTop: 5, flexDirection: 'row', marginBottom: 5 }}>
         <Typography size={14} bold={'400'} color={'black'}>POSTCODE: </Typography>
         <Typography size={14} style={{ textTransform: 'uppercase'}} left={5} color={'black'} >{postcode}</Typography>
        
            <TouchableOpacity
              onPress={() => {
                setPostCode(null);
                setName({ value: '', error: '' });
              }}
            >
              <FontAwesome
                name="pencil-square-o"
                size={20}
                color={COLORS.LGREEN}
                style={{ marginLeft: 10 }}
              />
            </TouchableOpacity>
          </View>
        )}
      </View> */}
     <div className="rounded">

      <iframe width="100%"
      frameBorder="0"
 hspace="0" vspace="0"
                        height="100%"
                        src= 
                        {generatePartnerUrl(HOME_BASE_URL,'TILE',iFrameParams)}
                        title="Home box" > 
                </iframe> 
                </div>
        {/* {postcode?
        <ImageBackground
        imageStyle={{ borderBottomLeftRadius:18,borderBottomRightRadius:18}}
        source={{uri:'https://i.pinimg.com/originals/04/30/a2/0430a263502e44a9c594096cc312982e.jpg'}}
        style={{height:'100%',justifyContent:'center',paddingBottom:5}}
        >
        {renderResultPage() }
        </ImageBackground>:
        <View style={{justifyContent:'center',alignItems:'center'}}>
          {renderInitialPage()}
        </View>
        }
       */}
      </View>
  );
});

const styles = StyleSheet.create({
  bannerWrapStyle: {
    width: 250,
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  exploreNowButtonStyle: {
    borderRadius: 10.0 * 2.0,
    paddingVertical: 10.0 - 5.0,
    paddingHorizontal: 10.0,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    marginTop: 10.0 - 2.0,
    marginBottom: 10.0 - 3.0,
  },
  menuItem: {
    padding: 0,
    margin: '5px',
    cursor: 'pointer',
    border: 'none',
  },
  menuItemWrapperActive: {
    border: '1px blue solid',
  },
  menuItemActive: {
    border: 1,
    borderColor: 'black',
  },
  //   .movie-card:hover {
  //     width: 330px;
  //     height: 190px;
  //   }
  scrollMenuRrrow: {
    padding: 20,
    cursor: 'pointer',
  },
  arrow: {
    color: 'red',
    fontSize: 40,
  },
});

export default HomeBoxCard;
